import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import Navbar from '../Navbar/Navbar';
import Footer from "../Navbar/Footer";
import { API_URL } from '../../constants';

const Signup = () => {
    const [mobileNumber, setMobileNumber] = useState('');
    const [otpSent, setOtpSent] = useState(false);
    const [otp, setOtp] = useState('');
    const [enteredOtp, setEnteredOtp] = useState('');
    const [otpVerified, setOtpVerified] = useState(false);
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();

    const validateForm = () => {
        const newErrors = {};
        if (!mobileNumber) {
            newErrors.mobileNumber = "Mobile number is required.";
        } else if (!/^\d{10}$/.test(mobileNumber)) {
            newErrors.mobileNumber = "Mobile number must be 10 digits.";
        }
        return newErrors;
    };

    useEffect(() => {
        const disableBack = () => {
            window.history.pushState(null, null, window.location.href);
            window.onpopstate = () => {
                window.history.go(1);
            };
        };
        disableBack();

        return () => {
            window.onpopstate = null;
        };
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length === 0) {
            try {
                Swal.fire({
                    title: 'Sending OTP...',
                    text: 'Please wait a moment.',
                    icon: 'info',
                    timer: 5000,
                    allowOutsideClick: false,
                    showConfirmButton: false,
                    didOpen: () => {
                        Swal.showLoading();
                    },

                });

                setTimeout(async () => {
                    const response = await fetch(`${API_URL}/send-otp`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({ mobileNumber })
                    });
                    const data = await response.json();
                    Swal.close();
                    if (data.success) {
                        setOtpSent(true);
                        setOtp(data.otp);
                        sessionStorage.setItem('otp', data.otp);
                        console.log(data.otp);
                        Swal.fire({
                            title: "Success!",
                            text: "OTP sent successfully.",
                            icon: "success",
                            allowOutsideClick: false
                        });
                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: data.error,
                            icon: "error",
                            allowOutsideClick: false
                        });
                    }

                }, 5000);
            } catch (error) {
                console.error('Error sending OTP:', error);
                Swal.fire({
                    title: "Error!",
                    text: "Failed to send OTP",
                    icon: "error",
                    allowOutsideClick: false
                });
            }

        } else {
            setErrors(validationErrors);
        }
    };

    const handleOtpVerification = (e) => {
        e.preventDefault();
        Swal.fire({
            title: 'Verifying OTP...',
            text: 'Please wait a moment.',
            icon: 'info',
            timer: 5000,
            allowOutsideClick: false,
            showConfirmButton: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });

        setTimeout(() => {
            Swal.close();
            if (enteredOtp === sessionStorage.getItem('otp')) {
                setOtpVerified(true);
                Swal.fire({
                    title: "Success!",
                    text: "OTP verified successfully.",
                    icon: "success",
                    allowOutsideClick: false
                });
            } else {
                Swal.fire({
                    title: "Error!",
                    text: "Invalid OTP entered",
                    icon: "error",
                    allowOutsideClick: false
                });
            }
        }, 2000);
    };


    const handleContinue = () => {
        sessionStorage.setItem('mobileNumber', mobileNumber);
        navigate('/signup-form');
    };

    return (
        <div>
            <>
                <header className="foi-header bg_header">
                    <div className="container">
                        <div className="container bg_navbar">
                            <Navbar />
                        </div>
                    </div>
                </header>
                <main className="page-auth" style={{ paddingTop: '60px' }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-10 offset-lg-1">
                                <section className="auth-wrapper">
                                    <div className="row">
                                        <div className="col-md-6 mb-4 mb-md-0">
                                            <h2 className="auth-section-title">Create account</h2>
                                            <p className="auth-section-subtitle">Create your account to continue.</p>
                                            {!otpSent ? (
                                                <form onSubmit={handleSubmit}>
                                                    <div className={`form-group ${errors.mobileNumber ? 'has-error' : ''}`}>
                                                        <label>Mobile Number</label>
                                                        <div className="input-group">
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text">+91</span>
                                                            </div>
                                                            <input
                                                                type="number"
                                                                className={`form-control ${errors.mobileNumber ? 'is-invalid' : ''}`}
                                                                placeholder="Enter Mobile Number"
                                                                value={mobileNumber}
                                                                onChange={(e) => setMobileNumber(e.target.value)}
                                                            />
                                                            {errors.mobileNumber && (
                                                                <div className="invalid-feedback">{errors.mobileNumber}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <button className="btn btn-primary btn-auth-submit" type="submit">Send OTP</button>
                                                </form>
                                            ) : (
                                                <form onSubmit={handleOtpVerification}>
                                                    <div className="form-group">
                                                        <label htmlFor="otp">Enter OTP <sup>*</sup></label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="otp"
                                                            name="otp"
                                                            placeholder="Enter OTP"
                                                            value={enteredOtp}
                                                            onChange={(e) => setEnteredOtp(e.target.value)}
                                                            disabled={otpVerified}
                                                        />
                                                    </div>
                                                    <button
                                                        className="btn btn-primary btn-auth-submit"
                                                        type="submit"
                                                        disabled={otpVerified}
                                                    >
                                                        Verify OTP
                                                    </button>
                                                    {otpVerified && (
                                                        <button
                                                            className="btn btn-secondary btn-auth-submit ml-2"
                                                            type="button"
                                                            onClick={handleContinue}
                                                        >
                                                            Continue
                                                        </button>
                                                    )}
                                                </form>
                                            )}
                                            <p className="mb-0">
                                                <Link to="/signin" className="text-dark font-weight-bold">Already have an account? Sign in</Link>
                                            </p>
                                        </div>
                                        <div className="col-md-6 d-flex align-items-center">
                                            <img src="assets/images/Register.png" alt="login" className="img-fluid" />
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </main>
                <Footer />
            </>
        </div>
    );
};

export default Signup;
