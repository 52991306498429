import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import Navbar from '../Navbar/Navbar';
import Footer from "../Navbar/Footer";
import { API_URL } from '../../constants';
import { useLocation } from 'react-router-dom';

const CategoryRestaurants = () => {
    const navigate = useNavigate();
    const [restaurants, setRestaurants] = useState([]);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const category = queryParams.get('category');
    const [cartItems, setCartItems] = useState([]);
    const [menuItemImages, setMenuItemImages] = useState({});

    useEffect(() => {
        const isAuthenticated = sessionStorage.getItem('user');
        if (!isAuthenticated) {
            navigate('/404');
        }
    }, [navigate]);

    useEffect(() => {
        const isAuthenticated = sessionStorage.getItem('user');
        if (!isAuthenticated) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please login to continue!',
                confirmButtonText: 'OK',
                onClose: () => {
                    navigate('/signin');
                }
            });
        }
    }, [navigate]);

    useEffect(() => {
        const fetchRestaurants = async () => {
            try {
                const response = await fetch(`${API_URL}/foodItems/categories/${category}/restaurants`);
                if (!response.ok) {
                    throw new Error('Failed to fetch restaurants');
                }
                const data = await response.json();
                setRestaurants(data.restaurants);
            } catch (error) {
                console.error('Error fetching restaurants:', error);
                Swal.fire("Error!", "Failed to fetch restaurants", "error");
            }
        };

        if (category) {
            fetchRestaurants();
        }
    }, [category]);

    useEffect(() => {
        const storedCartItems = JSON.parse(sessionStorage.getItem('cartItems')) || [];
        setCartItems(storedCartItems);
    }, []);

    const fetchMenuItemImages = async (restaurantId, category) => {
        try {
            const response = await fetch(`${API_URL}/foodItems/${restaurantId}/${category}/menu-items`);
            if (!response.ok) {
                throw new Error('Failed to fetch menu items');
            }
            const data = await response.json();
            if (data.menuItems && data.menuItems.length > 0) {
                const randomMenuItem = data.menuItems[Math.floor(Math.random() * data.menuItems.length)];
                setMenuItemImages(prevImages => ({ ...prevImages, [restaurantId]: randomMenuItem.image }));
            }
        } catch (error) {
            console.error('Error fetching menu items:', error);
            Swal.fire("Error!", "Failed to fetch menu items", "error");
        }
    };

    useEffect(() => {
        if (restaurants.length > 0) {
            restaurants.forEach(restaurant => {
                fetchMenuItemImages(restaurant.userId, category);
            });
        }
    }, [restaurants, category]);

    const handleRestaurantClick = (restaurantId) => {
        navigate(`/restaurants/${restaurantId}/menu`);
    };

    const cartQuantity = cartItems.reduce((total, item) => total + item.quantity, 0);

    const goToCartPage = () => {
        navigate('/cart');
    };

    return (
        <>
            <header className="foi-header bg_header">
                <div className="container">
                    <div className="container bg_navbar">
                        <Navbar />
                    </div>
                </div>
            </header>
            <main className="page-about" style={{ paddingTop: '120px' }}>
                <section className="page-about container">
                    <div className="container">
                        <h1 style={{ textTransform: 'capitalize' }}>{category}</h1>
                        <h4>Restaurants to explore</h4>
                        {restaurants.length === 0 ? (
                            <div className="text-center mt-5">
                                <p>No restaurants found under {category}</p>
                            </div>
                        ) : (
                            <section className="foi-page-section mb-5 container" style={{ paddingTop: '0px' }}>
                                <div className="item container">
                                    <div className="row">
                                        {restaurants.map((restaurant, index) => (
                                            <div className="col-md-4 col-lg-3" key={index}>
                                                <div className="team-member-card mb-4 food_items" onClick={() => handleRestaurantClick(restaurant.userId)}>
                                                    {menuItemImages[restaurant.userId] ? (
                                                        <img src={`data:image/jpeg;base64,${menuItemImages[restaurant.userId]}`} className="team-member-avatar_2" alt={restaurant.restaurantName} />
                                                    ) : (
                                                        <div className="team-member-avatar-placeholder">Loading...</div>
                                                    )}
                                                    <h6 style={{textTransform:"capitalize", color: '#414449', textAlign: 'center', paddingBottom: '5px' }} className="mb-0">{restaurant.restaurantName}</h6>
                                                    <h6 style={{ color: '#414449', textAlign: 'center', fontSize: '14px', paddingBottom: '5px' }} className="mb-0">
                                                        <img src={require('../assets/images/food/restaurant/star.png')} alt="star" width="14px" className="blog-post-thumbnail mr-md-1" />
                                                        <span style={{ display: 'inline-block', marginLeft: '5px' }}>{restaurant.rating.toFixed(1)}</span>
                                                        <span style={{ marginLeft: '10px' }}><img src={require('../assets/images/food/restaurant/time.png')} alt="star" width="14px" className="blog-post-thumbnail mr-md-1" />20-30 min</span>
                                                    </h6>
                                                    <h6 style={{ fontWeight: '400', color: '#414449', textAlign: 'center', paddingBottom: '5px' }} className="mb-0">{restaurant.description}</h6>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </section>
                        )}
                    </div>
                </section>
                {cartQuantity > 0 && (
                    <div className="shopping-cart fixed-bottom-right" onClick={goToCartPage}>
                        <span className="cart-icon">
                            <img src={require('../assets/img/shopping-cart.gif')} alt="blog" width="45px" className="blog-post-thumbnail" />
                            <span className="cart_shop" style={{ fontWeight: '700', fontSize: '24px', color: '#000' }}>{cartQuantity}</span>
                        </span>
                    </div>
                )}
            </main>
            <Footer />
        </>
    );
}

export default CategoryRestaurants;
