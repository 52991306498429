// MedicineDetails.js
import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import Swal from 'sweetalert2';
import Navbar from '../Navbar/Navbar';
import Footer from "../Navbar/Footer";
import { API_URL } from '../../constants';

const MedicineDetails = ({ cart, totalItemsInCart, addToCart, increaseQuantity, decreaseQuantity, resetCartCount }) => {
    const { id } = useParams();
    const [medicine, setMedicine] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        // Check if the user is authenticated (You can implement your own logic here)
        const isAuthenticated = sessionStorage.getItem('user'); // Example: Check if user data exists in session storage
        if (!isAuthenticated) {
            // If user is not authenticated, redirect to the No Access page
            navigate('/404');
        }
    }, [navigate]);


    useEffect(() => {
        // Check if the user is authenticated (You can implement your own logic here)
        const isAuthenticated = sessionStorage.getItem('user'); // Example: Check if user data exists in session storage
        if (!isAuthenticated) {
            // If user is not authenticated, show a sweet alert and redirect to login
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please login to continue!',
                confirmButtonText: 'OK',
                onClose: () => {
                    navigate('/signin');
                }
            });
        }
    }, [navigate]);

    useEffect(() => {
        const isAuthenticated = sessionStorage.getItem('user');
        if (!isAuthenticated) {
            navigate('/404');
        }
    }, [navigate]);

    useEffect(() => {
        const isAuthenticated = sessionStorage.getItem('user');
        if (!isAuthenticated) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please login to continue!',
                confirmButtonText: 'OK',
                onClose: () => {
                    navigate('/signin');
                }
            });
        }
    }, [navigate]);

    useEffect(() => {
        const fetchMedicineDetails = async () => {
            try {
                const response = await fetch(`${API_URL}/pharmacyMedicine/product/${id}`);
                const data = await response.json();
                if (response.ok) {
                    setMedicine(data.medicine);
                } else {
                    Swal.fire("Error", data.message, "error");
                }
            } catch (error) {
                Swal.fire("Error", "An error occurred while fetching medicine details.", "error");
            }
        };

        fetchMedicineDetails();
    }, [id]);

    const formatDate = (dateString) => {
        if (!dateString) return "";
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    return (
        <>
            <header className="foi-header bg_header">
                <div className="container">
                    <div className="container bg_navbar">
                        <Navbar />
                    </div>
                </div>
            </header>
            <main className="page-blog-post">
                <section style={{ paddingTop: "120px" }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                {medicine && (
                                    <div id="medicineCarousel" className="carousel slide" data-ride="carousel" style={{ borderRadius: '30px' }}>
                                        <div className="carousel-inner">
                                            {medicine.images.map((image, index) => (
                                                <div key={index} className={`carousel-item carousel-item_1 ${index === 0 ? 'active' : ''}`}>
                                                    <img src={`data:image/jpeg;base64,${image}`} className="d-block w-100" alt={`Slide ${index}`} style={{ maxWidth: '523px', height: 'auto', maxHeight: '359px', borderRadius: '30px' }} />
                                                </div>
                                            ))}
                                        </div>
                                        <a className="carousel-control-prev" href="#medicineCarousel" role="button" data-slide="prev" style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", borderRadius: "50%", width: "40px", height: "40px", top: "50%", transform: "translateY(-50%)", left: "10px" }}>
                                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                            <span className="sr-only">Previous</span>
                                        </a>
                                        <a className="carousel-control-next" href="#medicineCarousel" role="button" data-slide="next" style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", borderRadius: "50%", width: "40px", height: "40px", top: "50%", transform: "translateY(-50%)", right: "10px" }}>
                                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                            <span className="sr-only">Next</span>
                                        </a>
                                    </div>
                                )}
                            </div>
                            <div className="col-lg-6">
                                {medicine && (
                                    <section className="blog-post-author-info-section">
                                        <div className="row">
                                            <div className="col-lg-10 offset-lg-2">
                                                <div style={{ paddingBottom: '20px' }}>
                                                    <h5 className="blog-post-author-info-section-title" style={{ lineHeight: '1.2rem' }}>{medicine.medicineName}</h5>
                                                </div>
                                                <div className="blog-post-author-info media">
                                                    <div className="media-body">
                                                        <div className="d-flex flex-wrap align-items-center">
                                                            <nav className="author-social-links">
                                                                <h5 style={{ fontSize: '16px', color: '#000' }}>MRP <span style={{ textDecoration: "line-through", color: '#808080' }}>₹ {medicine.actualPrice.toFixed(2)}</span></h5>
                                                                <h5 style={{ fontSize: '16px', color: '#262c67' }}>₹ {medicine.discountPrice.toFixed(2)}</h5>
                                                            </nav>
                                                        </div>
                                                        <div className="py-4">
                                                            <p className="clearfix">
                                                                <span className="float-left">Manufacturer Name</span>
                                                                <span className="float-right text-muted">{medicine.manufacturerName}</span>
                                                            </p>
                                                            <p className="clearfix">
                                                                <span className="float-left">Manufacturer Address</span>
                                                                <span className="float-right text-muted">{medicine.manufacturerAddress}</span>
                                                            </p>
                                                            <p className="clearfix">
                                                                <span className="float-left">Country Of Origin</span>
                                                                <span className="float-right text-muted">{medicine.countryOfOrigin}</span>
                                                            </p>
                                                            <p className="clearfix">
                                                                <span className="float-left">Expires On or After</span>
                                                                <span className="float-right text-muted">{formatDate(medicine.expireDate)}</span>
                                                            </p>
                                                        </div>
                                                        {cart[medicine._id] ? (
                                                            <div className="d-flex align-items-center mt-auto">
                                                                <button className="btn btn-primary" onClick={() => decreaseQuantity(medicine._id)}>-</button>
                                                                <span className="mx-2 btn btn-secondary" style={{ fontSize: '18px', fontWeight: '700' }}>{cart[medicine._id]}</span>
                                                                <button className="btn btn-primary" onClick={() => increaseQuantity(medicine._id)}>+</button>
                                                            </div>
                                                        ) : (
                                                            <button className="btn btn-primary btn-rounded mt-auto" onClick={() => addToCart(medicine._id)}>Add to Cart</button>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                )}
                            </div>
                        </div>
                    </div>
                </section>
                {totalItemsInCart > 0 && (
                    <Link to="/cart">
                        <div className="shopping-cart fixed-bottom-right">
                            <span className="cart-icon">
                                <img src={require('../assets/img/shopping-cart.gif')} alt="cart" width="45px" className="cart-icon-image" />
                                <span style={{ fontWeight: '700', fontSize: '24px', color:'#000' }}>{totalItemsInCart}</span>
                            </span>
                        </div>
                    </Link>
                )}
            </main>
            <Footer />
        </>
    );
}

export default MedicineDetails;
