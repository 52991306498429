import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import Navbar from '../Navbar/Navbar';
import Footer from "../Navbar/Footer";
import { API_URL } from '../../constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const Signin = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({});
    const [sessionError, setSessionError] = useState(false);
    const [logoutSuccess, setLogoutSuccess] = useState(false);
    const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const disableBack = () => {
            window.history.pushState(null, null, window.location.href);
            window.onpopstate = () => {
                window.history.go(1);
            };
        };
        disableBack();

        return () => {
            window.onpopstate = null;
        };
    }, []);

    useEffect(() => {
        if (location.state?.sessionExpired) {
            setSessionError(true);
        }
        if (location.state?.loggedOut) {
            setLogoutSuccess(true);
        }
    }, [location.state]);

    const validateForm = () => {
        const newErrors = {};
        if (!email) {
            newErrors.email = "Email is required.";
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            newErrors.email = "Email is invalid.";
        }
        if (!password) {
            newErrors.password = "Password is required.";
        }
        return newErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length === 0) {
            Swal.fire({
                title: 'Validating',
                text: 'Please wait...',
                icon: 'info',
                timer: 5000,
                showConfirmButton: false,
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading();
                }
            });

            setTimeout(async () => {
                try {
                    const response = await fetch(`${API_URL}/login`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ email, password }),
                    });

                    const data = await response.json();
                    if (data.status === 200) {
                        sessionStorage.setItem('user', JSON.stringify(data.data));

                        Swal.fire("Success!", "Validation Successful. Click OK to continue.", "success").then(() => {
                            navigate('/services-home');
                        });
                    } else {
                        Swal.fire("Error", data.message, "error");
                    }
                } catch (error) {
                    Swal.fire("Error", "An error occurred during login.", "error");
                }
            }, 5000);
        } else {
            setErrors(validationErrors);
        }
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        if (errors.email) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                email: "",
            }));
        }
        if (sessionError) {
            setSessionError(false);
        }
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        if (errors.password) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                password: "",
            }));
        }
        if (sessionError) {
            setSessionError(false);
        }
    };

    return (
        <>
            <header className="foi-header bg_header">
                <div className="container">
                    <div className="container bg_navbar">
                        <Navbar />
                    </div>
                </div>
            </header>
            <main className="page-auth" style={{ paddingTop: '60px' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 offset-lg-1">
                            <section className="auth-wrapper">
                                <div className="row">
                                    <div className="col-md-6 mb-4 mb-md-0">
                                        <h2 className="auth-section-title">Sign In</h2>
                                        <p className="auth-section-subtitle">Sign in to your account to continue.</p>
                                        {sessionError && (
                                            <div className="alert alert-danger" role="alert">
                                                Your session has expired. Please log in to continue.
                                            </div>
                                        )}
                                        {logoutSuccess && <div className="alert alert-success">Successfully logged out.</div>}

                                        <form onSubmit={handleSubmit}>
                                            <div className={`form-group ${errors.email ? 'has-error' : ''}`}>
                                                <label htmlFor="email">Email <sup>*</sup></label>
                                                <input
                                                    type="email"
                                                    className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                                                    id="email"
                                                    name="email"
                                                    placeholder="Email *"
                                                    value={email}
                                                    onChange={handleEmailChange}
                                                />
                                                {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                                            </div>
                                            <div className={`form-group ${errors.password ? 'has-error' : ''}`}>
                                                <label htmlFor="password">Password <sup>*</sup></label>
                                                <div className="input-group">
                                                    <input
                                                        type={showPassword ? "text" : "password"}
                                                        className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                                                        id="password"
                                                        name="password"
                                                        placeholder="Password *"
                                                        value={password}
                                                        onChange={handlePasswordChange}
                                                    />
                                                    <div className="input-group-append" style={{marginLeft:'2px'}}>
                                                        <button
                                                            type="button"
                                                            className="btn btn-secondary"
                                                            onClick={() => setShowPassword(!showPassword)}
                                                        >
                                                            <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                                                        </button>
                                                    </div>
                                                {errors.password && <div className="invalid-feedback">{errors.password}</div>}

                                                </div>
                                            </div>
                                            <div className="form-actions-wrapper d-flex flex-wrap align-items-center justify-content-between">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="keepLogin" />
                                                    <label className="form-check-label" htmlFor="keepLogin">
                                                        keep me Signin
                                                    </label>
                                                </div>
                                                <Link to='/forgot-password' className="forgot-password-link">Forgot password?</Link>
                                            </div>
                                            <button className="btn btn-primary btn-auth-submit" type="submit">Submit</button>
                                        </form>
                                        <p className="mb-0">
                                            <Link to='/signup' className="text-dark font-weight-bold">New User? Create Account</Link>
                                        </p>
                                    </div>
                                    <div className="col-md-6 d-flex align-items-center">
                                        <img src="assets/images/login.png" alt="login" className="img-fluid" />
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </>
    );
};

export default Signin;
