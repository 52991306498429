import React, { useEffect, useState } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import Navbar from '../../Navbar/Navbar';
import Footer from '../../Navbar/Footer';
import { API_URL } from '../../../constants';

const LabTestsPage = () => {
    const { centerId, centerName } = useParams();
    const [categories, setCategories] = useState([]);
    const navigate = useNavigate();
    const [cartItems, setCartItems] = useState([]);

    useEffect(() => {
        // Check if the user is authenticated (You can implement your own logic here)
        const isAuthenticated = sessionStorage.getItem('user'); // Example: Check if user data exists in session storage
        if (!isAuthenticated) {
            // If user is not authenticated, redirect to the No Access page
            navigate('/404');
        }
    }, [navigate]);


    useEffect(() => {
        // Check if the user is authenticated (You can implement your own logic here)
        const isAuthenticated = sessionStorage.getItem('user'); // Example: Check if user data exists in session storage
        if (!isAuthenticated) {
            // If user is not authenticated, show a sweet alert and redirect to login
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please login to continue!',
                confirmButtonText: 'OK',
                onClose: () => {
                    navigate('/signin');
                }
            });
        }
    }, [navigate]);;

    useEffect(() => {
        // Fetch categories for the specified center
        const fetchCategories = async () => {
            try {
                const response = await fetch(`${API_URL}/diagnostic-categories/${centerId}/${encodeURIComponent(centerName)}`);
                const data = await response.json();
                setCategories(data);
            } catch (error) {
                console.error('Error fetching diagnostic categories:', error);
            }
        };

        fetchCategories();
    }, [centerId, centerName]);

    useEffect(() => {
        // Load cart items from local storage if available
        const storedCartItems = localStorage.getItem('cartItems');
        if (storedCartItems) {
            setCartItems(JSON.parse(storedCartItems));
        }
    }, []);

    const handleOpenCart = () => {
        navigate("/testcart", { state: { cartItems } });
    };

    const totalItemsInCart = cartItems.length;

    return (
        <>
            <header className="foi-header bg_header">
                <div className="container">
                    <div className="container bg_navbar">
                        <Navbar />
                    </div>
                </div>
            </header>
            <main className="page-about">
                <section style={{ paddingTop: "120px" }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div id="landingClientCarousel" className="carousel slide landing-client-carousel" data-ride="carousel">
                                    <div className="carousel-inner" role="listbox">
                                        <div className="carousel-item active">
                                            <div className="d-flex flex-wrap justify-content-center">
                                                <img style={{ borderRadius: '20px' }} src={require('../../assets/img/slide-1.png')} alt="Slide 1" width="1000" className="img-fluid" />
                                            </div>
                                        </div>
                                        <div className="carousel-item">
                                            <div className="d-flex flex-wrap justify-content-center">
                                                <img style={{ borderRadius: '20px' }} src={require('../../assets/img/slide-2.png')} alt="Slide 2" width="1000" className="img-fluid" />
                                            </div>
                                        </div>
                                        <div className="carousel-item">
                                            <div className="d-flex flex-wrap justify-content-center">
                                                <img style={{ borderRadius: '20px' }} src={require('../../assets/img/slide-3.png')} alt="Slide 3" width="1000" className="img-fluid" />
                                            </div>
                                        </div>
                                        <div className="carousel-item">
                                            <div className="d-flex flex-wrap justify-content-center">
                                                <img style={{ borderRadius: '20px' }} src={require('../../assets/img/slide-4.png')} alt="Slide 4" width="1000" className="img-fluid" />
                                            </div>
                                        </div>
                                    </div>
                                    <ol className="carousel-indicators">
                                        <li data-target="#landingClientCarousel" data-slide-to="0" className="active"></li>
                                        <li data-target="#landingClientCarousel" data-slide-to="1"></li>
                                        <li data-target="#landingClientCarousel" data-slide-to="2"></li>
                                        <li data-target="#landingClientCarousel" data-slide-to="3"></li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section style={{ paddingTop: "20px" }}>
                    <div className="container">
                        <h5 style={{ paddingBottom: '20px' }}>Tests Categories at {decodeURIComponent(centerName)}</h5>
                        <div className="row">
                            {categories.map((category, index) => (
                                <div key={index} className="col-lg-4 mb-4">
                                    <div className="card pricing-card active w-100" style={{ padding: '10px', borderRadius: "10px", boxShadow: '0 0 15px rgba(24, 24, 24, .161)' }}>
                                        <div className="card-body d-flex flex-column">
                                            <h5>{category.categoryName}</h5>
                                            <center>
                                                <Link
                                                    to={`/lab-tests/${centerId}/${encodeURIComponent(centerName)}/${encodeURIComponent(category.categoryName)}`}
                                                    className="btn btn-primary btn-rounded"
                                                    style={{ maxWidth: '40%' }}
                                                >
                                                    View All
                                                </Link>
                                            </center>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
            {totalItemsInCart > 0 && (
                <div className="shopping-cart fixed-bottom-right">
                    <span className="cart-icon" onClick={handleOpenCart} style={{ fontWeight: '700', fontSize: '20px' }}>
                        <img src={require("../../assets/img/shopping-cart.gif")} alt="cart" width="45px" />
                        {totalItemsInCart}
                    </span>
                </div>
            )}
        </>
    );
}

export default LabTestsPage;
