import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import Navbar from '../Navbar/Navbar';
import Footer from '../Navbar/Footer';
import { API_URL } from '../../constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const Step2 = () => {
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [dob, setDob] = useState('');
    const [gender, setGender] = useState('');
    const [profilePhoto, setProfilePhoto] = useState(null);
    const [errors, setErrors] = useState({});
    const [preview, setPreview] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const mobileNumber = sessionStorage.getItem('mobileNumber');
    const navigate = useNavigate();

    useEffect(() => {
        const disableBack = () => {
            window.history.pushState(null, null, window.location.href);
            window.onpopstate = () => {
                window.history.go(1);
            };
        };
        disableBack();

        return () => {
            window.onpopstate = null;
        };
    }, []);

    const validateForm = () => {
        const newErrors = {};
        if (!firstName) {
            newErrors.firstName = "First Name is required.";
        } else if (!/^[a-zA-Z]+$/.test(firstName)) {
            newErrors.firstName = "First Name can only contain letters.";
        }
        if (!lastName) {
            newErrors.lastName = "Last Name is required.";
        } else if (!/^[a-zA-Z]+$/.test(lastName)) {
            newErrors.lastName = "Last Name can only contain letters.";
        }
        if (!email) {
            newErrors.email = "Email is required.";
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            newErrors.email = "Email is invalid.";
        }
        if (!password) {
            newErrors.password = "Password is required.";
        }
        if (!confirmPassword) {
            newErrors.confirmPassword = "Confirm Password is required.";
        } else if (password !== confirmPassword) {
            newErrors.confirmPassword = "Passwords do not match.";
        }
        if (!dob) {
            newErrors.dob = "Date of Birth is required.";
        } else if (new Date(dob) > new Date()) {
            newErrors.dob = "Date of Birth cannot be in the future.";
        }
        if (!gender) {
            newErrors.gender = "Gender is required.";
        }
        if (!profilePhoto) {
            newErrors.profilePhoto = "Profile photo is required.";
        }
        return newErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length === 0) {
            const formData = new FormData();
            formData.append('firstName', firstName);
            formData.append('lastName', lastName);
            formData.append('email', email);
            formData.append('phone', mobileNumber); // Using mobile number from sessionStorage
            formData.append('password', password);
            formData.append('dob', dob);
            formData.append('gender', gender);
            formData.append('profilePhoto', profilePhoto);

            try {
                const response = await fetch(`${API_URL}/register`, {
                    method: 'POST',
                    body: formData
                });

                if (response.ok) {
                    Swal.fire("Success!", "Account created successfully.", "success").then(() => {
                        navigate('/signin');
                    });
                } else {
                    const responseData = await response.json();
                    Swal.fire("Error!", responseData.message, "error");
                }
            } catch (error) {
                console.error('Error during form submission:', error.message);
                Swal.fire("Error!", "An error occurred during form submission.", "error");
            }
        } else {
            setErrors(validationErrors);
        }
    };

    const handleProfilePhotoChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setProfilePhoto(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <>
            <header className="foi-header bg_header">
                <div className="container">
                    <div className="container bg_navbar">
                        <Navbar />
                    </div>
                </div>
            </header>
            <main className="page-auth" style={{paddingTop:'50px'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 offset-lg-1">
                            <section className="auth-wrapper">
                                <div className="row">
                                    <div className="col-md-6 mb-4 mb-md-0">
                                        <h2 className="auth-section-title">Create account</h2>
                                        <p className="auth-section-subtitle">Create your account to continue.</p>
                                        <form onSubmit={handleSubmit}>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className={`form-group ${errors.firstName ? 'has-error' : ''}`}>
                                                        <label>First Name <sup>*</sup></label>
                                                        <input
                                                            type="text"
                                                            className={`form-control ${errors.firstName ? 'is-invalid' : ''}`}
                                                            id="firstName"
                                                            name="firstName"
                                                            placeholder="Enter First Name"
                                                            value={firstName}
                                                            onChange={(e) => setFirstName(e.target.value)}
                                                        />
                                                        {errors.firstName && <div className="invalid-feedback">{errors.firstName}</div>}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className={`form-group ${errors.lastName ? 'has-error' : ''}`}>
                                                        <label>Last Name <sup>*</sup></label>
                                                        <input
                                                            type="text"
                                                            className={`form-control ${errors.lastName ? 'is-invalid' : ''}`}
                                                            id="lastName"
                                                            name="lastName"
                                                            placeholder="Enter Last Name"
                                                            value={lastName}
                                                            onChange={(e) => setLastName(e.target.value)}
                                                        />
                                                        {errors.lastName && <div className="invalid-feedback">{errors.lastName}</div>}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={`form-group ${errors.email ? 'has-error' : ''}`}>
                                                <label htmlFor="email">Email <sup>*</sup></label>
                                                <input
                                                    type="email"
                                                    className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                                                    id="email"
                                                    name="email"
                                                    placeholder="Email"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                />
                                                {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                                            </div>
                                            <div className={`form-group ${errors.password ? 'has-error' : ''}`}>
                                                <label htmlFor="password">Password <sup>*</sup></label>
                                                <div className="input-group">
                                                    <input
                                                        type={showPassword ? "text" : "password"}
                                                        className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                                                        id="password"
                                                        name="password"
                                                        placeholder="Password"
                                                        value={password}
                                                        onChange={(e) => setPassword(e.target.value)}
                                                    />
                                                    <div className="input-group-append" style={{ marginLeft: '2px' }}>
                                                        <button
                                                            type="button"
                                                            className="btn btn-secondary"
                                                            onClick={() => setShowPassword(!showPassword)}
                                                        >
                                                            <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                                                        </button>
                                                    </div>
                                                    {errors.password && <div className="invalid-feedback">{errors.password}</div>}
                                                </div>
                                            </div>
                                            <div className={`form-group ${errors.confirmPassword ? 'has-error' : ''}`}>
                                                <label htmlFor="confirmPassword">Confirm Password <sup>*</sup></label>
                                                <div className="input-group">
                                                    <input
                                                        type={showConfirmPassword ? "text" : "password"}
                                                        className={`form-control ${errors.confirmPassword ? 'is-invalid' : ''}`}
                                                        id="confirmPassword"
                                                        name="confirmPassword"
                                                        placeholder="Confirm Password"
                                                        value={confirmPassword}
                                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                                    />
                                                    <div className="input-group-append" style={{ marginLeft: '2px' }}>
                                                        <button
                                                            type="button"
                                                            className="btn btn-secondary"
                                                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                                        >
                                                            <FontAwesomeIcon icon={showConfirmPassword ? faEyeSlash : faEye} />
                                                        </button>
                                                    </div>
                                                    {errors.confirmPassword && <div className="invalid-feedback">{errors.confirmPassword}</div>}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className={`form-group ${errors.dob ? 'has-error' : ''}`}>
                                                        <label>Date of Birth <sup>*</sup></label>
                                                        <input
                                                            type="date"
                                                            className={`form-control ${errors.dob ? 'is-invalid' : ''}`}
                                                            id="dob"
                                                            name="dob"
                                                            placeholder="Date of Birth"
                                                            value={dob}
                                                            onChange={(e) => setDob(e.target.value)}
                                                        />
                                                        {errors.dob && <div className="invalid-feedback">{errors.dob}</div>}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className={`form-group ${errors.gender ? 'has-error' : ''}`}>
                                                        <label>Gender <sup>*</sup></label>
                                                        <select
                                                            className={`form-control ${errors.gender ? 'is-invalid' : ''}`}
                                                            id="gender"
                                                            name="gender"
                                                            value={gender}
                                                            onChange={(e) => setGender(e.target.value)}
                                                        >
                                                            <option value="">Select Gender</option>
                                                            <option value="male">Male</option>
                                                            <option value="female">Female</option>
                                                            <option value="other">Other</option>
                                                        </select>
                                                        {errors.gender && <div className="invalid-feedback">{errors.gender}</div>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={`form-group ${errors.profilePhoto ? 'has-error' : ''}`}>
                                                <label>Profile Photo <sup>*</sup></label>
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    className={`form-control-file ${errors.profilePhoto ? 'is-invalid' : ''}`}
                                                    id="profilePhoto"
                                                    name="profilePhoto"
                                                    onChange={handleProfilePhotoChange}
                                                />
                                                {errors.profilePhoto && <div className="invalid-feedback">{errors.profilePhoto}</div>}
                                                {preview && (
                                                    <img src={preview} alt="Preview" className="img-thumbnail mt-2" style={{ maxWidth: '200px' }} />
                                                )}
                                            </div>
                                            <div className="text-center mt-4">
                                                <button type="submit" className="btn btn-primary btn-lg btn-block">
                                                    Create Account
                                                </button>
                                            </div>
                                        </form>
                                        <p className="mb-0" style={{paddingTop:'20px'}}>
                                            <Link to="/signin" className="text-dark font-weight-bold">Already have an account? Sign in</Link>
                                        </p>
                                    </div>
                                    <div className="col-md-6 d-flex align-items-center">
                                        <img src="assets/images/Register.png" alt="login" className="img-fluid" />
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </>
    );
};

export default Step2;
