import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../Navbar/Navbar';
import Swal from 'sweetalert2';
import Footer from "../Navbar/Footer";
import '../Health/CategoryMedicines.css';

const FoodCart = () => {
    const [cartItems, setCartItems] = useState(JSON.parse(sessionStorage.getItem('cartItems')) || []);
    const navigate = useNavigate();

    useEffect(() => {
        const isAuthenticated = sessionStorage.getItem('user');
        if (!isAuthenticated) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please login to continue!',
                confirmButtonText: 'OK',
                onClose: () => {
                    navigate('/signin');
                }
            });
        }
    }, [navigate]);

    const updateCartInSessionStorage = (updatedCartItems) => {
        sessionStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
    };

    const increaseQuantity = (itemId) => {
        const updatedCartItems = cartItems.map(item =>
            item.itemId === itemId ? { ...item, quantity: item.quantity + 1 } : item
        );
        setCartItems(updatedCartItems);
        updateCartInSessionStorage(updatedCartItems);
    };

    const decreaseQuantity = (itemId) => {
        const itemIndex = cartItems.findIndex(item => item.itemId === itemId);
        if (itemIndex !== -1) {
            const currentItem = cartItems[itemIndex];
            if (currentItem.quantity === 1) {
                Swal.fire({
                    title: 'Are you sure?',
                    text: "Do you want to remove this item from the cart?",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, remove it!'
                }).then((result) => {
                    if (result.isConfirmed) {
                        const updatedCartItems = [...cartItems];
                        updatedCartItems.splice(itemIndex, 1);
                        setCartItems(updatedCartItems);
                        updateCartInSessionStorage(updatedCartItems);
                        Swal.fire(
                            'Removed!',
                            'The item has been removed from the cart.',
                            'success'
                        );
                    }
                });
            } else {
                const updatedCartItems = cartItems.map(item =>
                    item.itemId === itemId ? { ...item, quantity: item.quantity - 1 } : item
                );
                setCartItems(updatedCartItems.filter(item => item.quantity > 0));
                updateCartInSessionStorage(updatedCartItems);
            }
        }
    };

    const removeItem = (itemId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "Do you want to remove this item from the cart?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, remove it!'
        }).then((result) => {
            if (result.isConfirmed) {
                const updatedCartItems = cartItems.filter(item => item.itemId !== itemId);
                setCartItems(updatedCartItems);
                updateCartInSessionStorage(updatedCartItems);
                Swal.fire(
                    'Removed!',
                    'The item has been removed from the cart.',
                    'success'
                );
            }
        });
    };

    const totalPrice = cartItems.reduce((total, item) => total + item.price * item.quantity, 0);

    const handleCheckout = () => {
        Swal.fire({
            title: 'Proceed to Checkout',
            text: 'This feature is not implemented yet.',
            icon: 'info',
            confirmButtonText: 'OK'
        });
    };

    return (
        <>
            <header className="foi-header bg_header">
                <div className="container">
                    <div className="container bg_navbar">
                        <Navbar />
                    </div>
                </div>
            </header>

            <main>
                <div style={{ paddingTop: "120px" }}>
                    <div className="container" style={{ border: '2px solid #e0e0e0', padding: '20px', borderRadius: '20px' }}>
                        <section className="page-header" style={{ paddingTop: '10px' }}>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb foi-breadcrumb">
                                    <li className="breadcrumb-item"><a href="/food-home">Home</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Cart</li>
                                </ol>
                            </nav>
                        </section>
                        <div className="row">
                            {cartItems.length > 0 ? (
                                <>
                                    <div className="col-lg-8 medicines-list" style={{ maxHeight: '400px', overflowY: 'auto', paddingTop: "20px", paddingBottom: '20px', paddingLeft: '10px' }}>
                                        {cartItems.map(item => (
                                            <div key={item.itemId} className="card career-card mb-2" style={{ borderRadius: '20px', padding: '10px' }}>
                                                <div className="card-body d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center" style={{ padding: '10px' }}>
                                                    <div className="col-md-3 mb-3 mb-md-0">
                                                        <h5 className="position-title" style={{ fontSize: "16px", fontWeight: '500' }}>{item.itemName}</h5>
                                                    </div>
                                                    <div className="col-md-3 d-flex flex-column flex-md-row align-items-start align-items-md-center">
                                                        <h5 className="mb-2 mb-md-0" style={{ fontSize: '16px', color: '#000' }}>
                                                            <span style={{ fontSize: '16px', color: '#262c67' }}>MRP ₹ {item.price.toFixed(2)}</span>
                                                        </h5>
                                                    </div>
                                                    <div className="col-md-3 d-flex align-items-center ml-0 ml-md-3">
                                                        <button className="btn btn-primary btn-sm" onClick={() => decreaseQuantity(item.itemId)}>-</button>
                                                        <span className="mx-2 btn btn-secondary btn-sm" style={{ fontSize: '18px', fontWeight: '700' }}>{item.quantity}</span>
                                                        <button className="btn btn-primary btn-sm" onClick={() => increaseQuantity(item.itemId)}>+</button>
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <button className="btn btn-danger ml-3" onClick={() => removeItem(item.itemId)}>Remove</button>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>

                                    <div className="col-lg-3 card career-card ml-lg-3 mt-3 mt-lg-0" style={{ padding: '10px', height: '300px', overflowY: 'auto', borderRadius: '20px' }}>
                                        <div className='card-body d-flex flex-column' style={{ padding: '10px' }}>
                                            <div style={{ borderBottom: '2px solid #e0e0e0', marginBottom: "5px" }}>
                                                <h5 style={{ color: '#050a4e', paddingBottom: '20px' }}>Payment Details</h5>
                                            </div>
                                            <div className="d-flex justify-content-between" style={{ paddingTop: '10px' }}>
                                                <p style={{ fontWeight: '700', color: '#050a4e', fontSize: "14px" }}>MRP Totals</p>
                                                <p style={{ fontWeight: '700' }}> ₹ {totalPrice.toFixed(2)}</p>
                                            </div>
                                            <div className="d-flex justify-content-between" style={{ borderTop: '2px solid #e0e0e0', marginTop: "15px" }}>
                                                <p style={{ fontWeight: '700', color: '#050a4e', fontSize: "14px" }}>Amount to Pay</p>
                                                <p style={{ fontWeight: '700' }}> ₹ {totalPrice.toFixed(2)}</p>
                                            </div>
                                            <button className="btn btn-primary mt-3" onClick={handleCheckout}>Checkout</button>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <div className="empty-cart">
                                    <h3>Your cart is empty</h3>
                                    <a href="/food-home" className="btn btn-primary">Go to Home</a>
                                </div>
                            )}

                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </>
    );
};

export default FoodCart;
