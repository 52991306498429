import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Navbar from '../Navbar/Navbar';
import Footer from "../Navbar/Footer";
import Swal from 'sweetalert2';

const Home = () => {
    useEffect(() => {
        const disableBack = () => {
            window.history.pushState(null, null, window.location.href);
            window.onpopstate = () => {
                window.history.go(1);
            };
        };
        disableBack();

        return () => {
            window.onpopstate = null;
        };
    }, []);

    return (
        <>
            <header className="foi-header landing-header bg_header">
                <div className="container">
                    <div className="container bg_navbar">
                        <Navbar />
                    </div>
                    <div className="header-content" style={{ paddingTop: '150px' }}>
                        <div className="row">
                            <div className="col-md-6">
                                <h1>Great app that makes your life awesome</h1>
                                <p className="text-dark">He has led a remarkable campaign, defying the traditional mainstream parties courtesy of his En Marche! movement.</p>
                                <Link className="btn btn-primary mb-4" to='/signin'>Get Started</Link>
                                <div className="my-2">
                                    <p className="header-app-download-title">GET OUR MOBILE APP</p>
                                </div>
                                <div>
                                    <button className="btn btn-app-download mr-2"><img src="assets/images/ios.svg" alt="App store" /></button>
                                    <button className="btn btn-app-download"><img src="assets/images/android.svg" alt="play store" /></button>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <img src={require('../assets/images/Group-23.png')} alt="app" width="388px" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <section className="py-5 mb-5">
                <div className="container">
                    <h2 className="section-title">Application Features</h2>
                    <div className="row">
                        <div className="col-lg-6 mb-4 mb-lg-0">
                            <h5>Secure Data</h5>
                            <p className="text-dark">Safeguard your information with our top-notch secure data services, ensuring confidentiality and peace of mind.</p>
                            <p className="mb-5">
                                <Link className="text-primary mb-5" to={'/features'}>Find out More</Link>
                            </p>
                            <h5>Fully functional</h5>
                            <p className="text-dark">Empower your success with our all-encompassing service solutions tailored to meet your unique needs.</p>
                            <p className="mb-5">
                                <Link className="text-primary mb-5" to={'/features'}>Find out More</Link>
                            </p>
                        </div>
                        <div className="col-lg-6 mb-3 mb-lg-0">
                            <h5>Live Chat</h5>
                            <p className="text-dark">Experience real-time support and instant solutions through our responsive live chat services.</p>
                            <p className="mb-5">
                                <Link className="text-primary mb-5" to={'/features'}>Find out More</Link>
                            </p>
                            <h5>Powerful dashboard</h5>
                            <p className="text-dark">Empower your operations with our powerful, user-friendly dashboard for streamlined control and real-time insights.</p>
                            <p className="mb-5">
                                <Link className="text-primary mb-5" to={'/features'}>Find out More</Link>
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="py-5 mb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 mb-5 mb-md-0">
                            <img src={require('../assets/images/Frame-4.png')} alt="special offers" className="img-fluid" width="492px" />
                        </div>
                        <div className="col-md-6">
                            <h2 className="section-title">Get special offers on the things you love</h2>
                            <p className="mb-5">He has led a remarkable campaign, defying the traditional mainstream parties courtesy of his En Marche! movement. For many, however, the campaign has become less about backing Macron and instead about voting against Le Pen, the National Front candidate.</p>
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <div className="media landing-feature">
                                        <span className="landing-feature-icon"></span>
                                        <div className="media-body">
                                            <h5>Essentials</h5>
                                            <p>All the basics for businesses that are just getting started.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 mb-3">
                                    <div className="media landing-feature">
                                        <span className="landing-feature-icon"></span>
                                        <div className="media-body">
                                            <h5>Premium</h5>
                                            <p>All the basics for businesses that are just getting started.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 mb-3">
                                    <div className="media landing-feature">
                                        <span className="landing-feature-icon"></span>
                                        <div className="media-body">
                                            <h5>Standard</h5>
                                            <p>All the basics for businesses that are just getting started.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="py-5 mb-5">
                <div className="container">
                    <h2>Choose the plan that’s right for your business</h2>
                    <p className="text-muted mb-5">Thank you for your very professional and prompt response. I wished I had found you before </p>
                    <div className="row">
                        <div className="col-lg-4 mb-4">
                            <div className="card pricing-card active">
                                <div className="card-body">
                                    <h3 className="mb-1">Starter</h3>
                                    <h3 className="mb-1 text-primary">Free</h3>
                                    <p className="payment-period">Per month</p>
                                    <p className="mb-4">Thank you for your very professional and prompt response.</p>
                                    <button className="btn btn-primary btn-rounded">Get Started</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 mb-4">
                            <div className="card pricing-card active">
                                <div className="card-body">
                                    <h3>Popular</h3>
                                    <h3 className="text-primary">₹ 2300.00</h3>
                                    <p className="payment-period">Per month</p>
                                    <p className="mb-4">Thank you for your very professional and prompt response.</p>
                                    <button className="btn btn-primary btn-rounded">Get Started</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 mb-4">
                            <div className="card pricing-card active">
                                <div className="card-body">
                                    <h3>Enterprise</h3>
                                    <h3 className="text-primary">₹ 4000.00</h3>
                                    <p className="payment-period">Per month</p>
                                    <p className="mb-4">Thank you for your very professional and prompt response.</p>
                                    <button className="btn btn-primary btn-rounded">Get Started</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="py-5 mb-5">
                <div className="container">
                    <h2>Satisfied Users</h2>
                    <p className="text-muted mb-5">Thank you for your very professional and prompt response. I wished I had found you before </p>
                    <div className="row">
                        <div className="col-md-4 foi-review mb-5 mb-md-0">
                            <div className="foi-rating">
                                <span className="fas fa-star checked"></span>
                                <span className="fas fa-star checked"></span>
                                <span className="fas fa-star checked"></span>
                                <span className="fas fa-star checked"></span>
                                <span className="fas fa-star checked"></span>
                            </div>
                            <h5 className="foi-review-heading">Great support available</h5>
                            <p className="foi-review-content">Thank you for your very professional and prompt response. I wished I had found you before I spent money on a competitors theme.</p>
                            <div className="media foi-review-user">
                                <img src={require('../assets/images/avatar/avatar_11.jpg')} alt="user" className="avatar" />
                                <div className="media-body">
                                    <h6 className="mb-0">Amarachi Nkechi</h6>
                                    <p>UX Designer</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 foi-review mb-5 mb-md-0">
                            <div className="foi-rating">
                                <span className="fas fa-star checked"></span>
                                <span className="fas fa-star checked"></span>
                                <span className="fas fa-star checked"></span>
                                <span className="fas fa-star checked"></span>
                                <span className="fas fa-star checked"></span>
                            </div>
                            <h5 className="foi-review-heading">Great support available</h5>
                            <p className="foi-review-content">Thank you for your very professional and prompt response. I wished I had found you before I spent money on a competitors theme.</p>
                            <div className="media foi-review-user">
                                <img src={require('../assets/images/avatar/avatar_12.jpg')} alt="user" className="avatar" />
                                <div className="media-body">
                                    <h6 className="mb-0">Margje Jutten</h6>
                                    <p>Developer</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 foi-review mb-5 mb-md-0">
                            <div className="foi-rating">
                                <span className="fas fa-star checked"></span>
                                <span className="fas fa-star checked"></span>
                                <span className="fas fa-star checked"></span>
                                <span className="fas fa-star checked"></span>
                                <span className="fas fa-star checked"></span>
                            </div>
                            <h5 className="foi-review-heading">Great support available</h5>
                            <p className="foi-review-content">Thank you for your very professional and prompt response. I wished I had found you before I spent money on a competitors theme.</p>
                            <div className="media foi-review-user">
                                <img src={require('../assets/images/avatar/avatar_13.jpg')} alt="user" className="avatar" />
                                <div className="media-body">
                                    <h6 className="mb-0">Monica Böttger</h6>
                                    <p>UX Designer</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}

export default Home;
