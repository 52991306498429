import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Swal from 'sweetalert2';
import { ToastContainer } from 'react-toastify';
import ScrollToTop from './Components/ScrollToTop/ScrollToTop';
import Home from './Components/Home/Home';
import About from './Components/Home/About';
import Features from './Components/Home/Features';
import Services from './Components/Home/Services';
import Contact from './Components/Home/Contact';
import Signup from './Components/Signup/Signup';
import Signin from './Components/Signup/Signin';
import Step2 from './Components/Signup/Step2';
import CustHome from './Components/Customer/CustHome';
import Error404 from './Components/Home/Error404';
import ForgotPassword from './Components/Signup/ForgotPassword';
import HealthHome from './Components/Health/HealthHome';
import MedicineHome from './Components/Health/MedicineHome';
import CategoryMedicines from './Components/Health/CategoryMedicines';
import MedicineDetails from './Components/Health/MedicineDetails';
import CartPage from './Components/Health/CartPage';
import CustomerDetailsPage from "./Components/Health/CustomerDetailsPage";
import PaymentPage from "./Components/Health/Payment";
import Orders from "./Components/Customer/Orders";
import LabtestHome from "./Components/Health/Labtests/LabtestHome";
import LabTestsPage from "./Components/Health/Labtests/LabTestsPage";
import CategoryTestsPage from "./Components/Health/Labtests/CategoryTestsPage";
import TestDetailsPage from "./Components/Health/Labtests/TestDetailsPage";
import TestCartPage from "./Components/Health/Labtests/TestCartPage";

import FoodHome from "./Components/Food/FoodHome";
import CategoryRestaurants from "./Components/Food/CategoryRestaurants";
import MenuItemsPage from "./Components/Food/MenuItemsPage";
import FoodCart from "./Components/Food/FoodCart";

function App() {
  const [cart, setCart] = useState(JSON.parse(localStorage.getItem('cart')) || {});
  const [totalItemsInCart, setTotalItemsInCart] = useState(0);
  const [refreshKey, setRefreshKey] = useState(0);

  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart));
    updateTotalItemsInCart(cart);
  }, [cart]);

  const updateTotalItemsInCart = (cart) => {
    const totalItems = Object.values(cart).reduce((sum, count) => sum + count, 0);
    setTotalItemsInCart(totalItems);
  };

  const addToCart = (medicineId) => {
    setCart((prevCart) => {
      const newCart = { ...prevCart };
      if (newCart[medicineId]) {
        newCart[medicineId] += 1;
      } else {
        newCart[medicineId] = 1;
      }
      return newCart;
    });
  };

  const increaseQuantity = (medicineId) => {
    setCart((prevCart) => {
      const newCart = { ...prevCart };
      newCart[medicineId] += 1;
      return newCart;
    });
  };

  const decreaseQuantity = (itemId) => {
    if (cart[itemId] === 1) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'This will remove the item from your cart.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, remove it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.isConfirmed) {
          const updatedCart = { ...cart };
          delete updatedCart[itemId];
          setCart(updatedCart);
          Swal.fire(
            'Removed!',
            'The item has been removed from your cart.',
            'success'
          );
        }
      });
    } else {
      const updatedCart = { ...cart };
      updatedCart[itemId] -= 1;
      setCart(updatedCart);
    }
  };

  const resetCartCount = () => {
    setCart({});
    localStorage.removeItem('cart'); // Clear cart from local storage
    setRefreshKey(prevKey => prevKey + 1); // Increment the refreshKey to trigger re-render
  };


  return (
    <div className="App">
      <ToastContainer theme='colored' position='top-center'></ToastContainer>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path='/' element={<Home key={refreshKey} />}></Route>
          <Route path='/about' element={<About key={refreshKey} />}></Route>
          <Route path='/features' element={<Features key={refreshKey} />}></Route>
          <Route path='/services' element={<Services key={refreshKey} />}></Route>
          <Route path='/contact' element={<Contact key={refreshKey} />}></Route>
          <Route path='/signup' element={<Signup key={refreshKey} />}></Route>
          <Route path='/signin' element={<Signin key={refreshKey} />}></Route>
          <Route path='/signup-form' element={<Step2 key={refreshKey} />}></Route>
          <Route path='/forgot-password' element={<ForgotPassword key={refreshKey} />}></Route>
          <Route path='/services-home' element={<CustHome key={refreshKey} />}></Route>
          <Route path='/404' element={<Error404 key={refreshKey} />}></Route>
          <Route path='/health-home' element={<HealthHome key={refreshKey} />}></Route>
          <Route path="/medicine-home" element={<MedicineHome cart={cart} totalItemsInCart={totalItemsInCart} addToCart={addToCart} increaseQuantity={increaseQuantity} decreaseQuantity={decreaseQuantity} key={refreshKey} />} />
          <Route path="/category/:category" element={<CategoryMedicines cart={cart} totalItemsInCart={totalItemsInCart} addToCart={addToCart} increaseQuantity={increaseQuantity} decreaseQuantity={decreaseQuantity} resetCartCount={resetCartCount} key={refreshKey} />} />
          <Route path="/medicine-details/:id" element={<MedicineDetails cart={cart} totalItemsInCart={totalItemsInCart} addToCart={addToCart} increaseQuantity={increaseQuantity} decreaseQuantity={decreaseQuantity} resetCartCount={resetCartCount} key={refreshKey} />} />
          <Route path="/cart" element={<CartPage cart={cart} totalItemsInCart={totalItemsInCart} increaseQuantity={increaseQuantity} decreaseQuantity={decreaseQuantity} key={refreshKey} />} />
          <Route path='/customer-details' element={<CustomerDetailsPage key={refreshKey} />}></Route>
          <Route path="/payment" element={<PaymentPage resetCartCount={resetCartCount} key={refreshKey} />} />
          <Route path="/orders" element={<Orders />} />
          <Route path="/lab-test-home" element={<LabtestHome />} />
          <Route path="/lab-tests/:centerId/:centerName" element={<LabTestsPage />} /> 
          <Route path="/lab-tests/:centerId/:centerName/:categoryName" element={<CategoryTestsPage />} />
          <Route path="/details/:testId" element={<TestDetailsPage />} />
          <Route path="/testcart" element={<TestCartPage />} />

          <Route path='/food-home' element={<FoodHome key={refreshKey} />}></Route>
          <Route path='/Category-Restaurants' element={<CategoryRestaurants key={refreshKey} />}></Route>
          <Route path="/restaurants/:restaurantId/menu" element={<MenuItemsPage />} />
          <Route path='/Food-Cart' element={<FoodCart key={refreshKey} />}></Route>


        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
