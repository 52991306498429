import React, { useEffect, useState } from "react";
import { useNavigate,Link  } from "react-router-dom";
import Swal from 'sweetalert2';
import Navbar from '../Navbar/Navbar';
import Footer from "../Navbar/Footer";
import { API_URL } from '../../constants';

const MedicineHome = ({ cart, totalItemsInCart, addToCart, increaseQuantity, decreaseQuantity,resetCartCount }) => {
    const [categories, setCategories] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const isAuthenticated = sessionStorage.getItem('user');
        if (!isAuthenticated) {
            navigate('/404');
        }
    }, [navigate]);

    useEffect(() => {
        const isAuthenticated = sessionStorage.getItem('user');
        if (!isAuthenticated) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please login to continue!',
                confirmButtonText: 'OK',
                onClose: () => {
                    navigate('/signin');
                }
            });
        } else {
            fetchCategories();
        }
    }, [navigate]);

    const fetchCategories = async () => {
        try {
            const response = await fetch(`${API_URL}/pharmacyMedicine/categories`);
            const data = await response.json();
            if (response.ok) {
                setCategories(data.categories);
            } else {
                Swal.fire("Error", data.message, "error");
            }
        } catch (error) {
            Swal.fire("Error", "An error occurred while fetching categories.", "error");
        }
    };
    useEffect(() => {
        // Check if the user is authenticated (You can implement your own logic here)
        const isAuthenticated = sessionStorage.getItem('user'); // Example: Check if user data exists in session storage
        if (!isAuthenticated) {
            // If user is not authenticated, redirect to the No Access page
            navigate('/404');
        }
    }, [navigate]);


    useEffect(() => {
        // Check if the user is authenticated (You can implement your own logic here)
        const isAuthenticated = sessionStorage.getItem('user'); // Example: Check if user data exists in session storage
        if (!isAuthenticated) {
            // If user is not authenticated, show a sweet alert and redirect to login
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please login to continue!',
                confirmButtonText: 'OK',
                onClose: () => {
                    navigate('/signin');
                }
            });
        }
    }, [navigate]);

    const handleCategoryClick = (category) => {
        navigate(`/category/${category}`);
    };

    return (
        <>
            <header className="foi-header bg_header">
                <div className="container">
                    <div className="container bg_navbar">
                        <Navbar />
                    </div>
                </div>
            </header>
            <main className="page-about">
                <section style={{ paddingTop: "120px" }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div id="landingClientCarousel" className="carousel slide landing-client-carousel" data-ride="carousel">
                                    <div className="carousel-inner" role="listbox">
                                        <div className="carousel-item active">
                                            <div className="d-flex flex-wrap justify-content-center">
                                                <img style={{ borderRadius: '20px' }} src={require('../assets/img/slide-1.png')} alt="Slide 1" width="1000" className="img-fluid" />
                                            </div>
                                        </div>
                                        <div className="carousel-item">
                                            <div className="d-flex flex-wrap justify-content-center">
                                                <img style={{ borderRadius: '20px' }} src={require('../assets/img/slide-2.png')} alt="Slide 2" width="1000" className="img-fluid" />
                                            </div>
                                        </div>
                                        <div className="carousel-item">
                                            <div className="d-flex flex-wrap justify-content-center">
                                                <img style={{ borderRadius: '20px' }} src={require('../assets/img/slide-3.png')} alt="Slide 3" width="1000" className="img-fluid" />
                                            </div>
                                        </div>
                                        <div className="carousel-item">
                                            <div className="d-flex flex-wrap justify-content-center">
                                                <img style={{ borderRadius: '20px' }} src={require('../assets/img/slide-4.png')} alt="Slide 4" width="1000" className="img-fluid" />
                                            </div>
                                        </div>
                                    </div>
                                    <ol className="carousel-indicators">
                                        <li data-target="#landingClientCarousel" data-slide-to="0" className="active"></li>
                                        <li data-target="#landingClientCarousel" data-slide-to="1"></li>
                                        <li data-target="#landingClientCarousel" data-slide-to="2"></li>
                                        <li data-target="#landingClientCarousel" data-slide-to="3"></li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className=" mb-5">
                    <div className="container">
                        <h3 style={{paddingBottom:'20px'}}>Shop By Categories</h3>
                        <div className="row">
                            {categories.map((category, index) => (
                                <div key={index} className="col-lg-4 mb-4 d-flex align-items-stretch">
                                    <div className="card pricing-card active w-100" style={{ backgroundColor: '#fbf5fd', borderRadius: "10px", boxShadow: '0 0 15px rgba(24, 24, 24, .161)' }}>
                                        <div className="card-body d-flex flex-column">
                                            <h4 style={{ textTransform: 'capitalize' }}>{category}</h4>
                                            <div className="mt-auto">
                                                <button
                                                    className="btn btn-primary btn-rounded"
                                                    onClick={() => handleCategoryClick(category)}
                                                >
                                                    View All
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
                {totalItemsInCart > 0 && (
                    <Link to="/cart">
                        <div className="shopping-cart fixed-bottom-right">
                            <span className="cart-icon">
                                <img src={require('../assets/img/shopping-cart.gif')} alt="cart" width="45px" className="cart-icon-image" />
                                <span style={{fontWeight:'700', fontSize:'24px', color:'#000'}}>{totalItemsInCart}</span>
                            </span>
                        </div>
                    </Link>
                )}
            </main>
            <Footer />
        </>
    );
}

export default MedicineHome;
