import React, { useState, useEffect } from 'react';
import Navbar from '../../Navbar/Navbar';
import Footer from '../../Navbar/Footer';
import Swal from 'sweetalert2';
import { Link, useNavigate } from 'react-router-dom';

const TestCartPage = () => {
    const navigate = useNavigate();
    const [cartItems, setCartItems] = useState([]);

    useEffect(() => {
        // Check if the user is authenticated (You can implement your own logic here)
        const isAuthenticated = sessionStorage.getItem('user'); // Example: Check if user data exists in session storage
        if (!isAuthenticated) {
            // If user is not authenticated, redirect to the No Access page
            navigate('/404');
        }
    }, [navigate]);


    useEffect(() => {
        // Check if the user is authenticated (You can implement your own logic here)
        const isAuthenticated = sessionStorage.getItem('user'); // Example: Check if user data exists in session storage
        if (!isAuthenticated) {
            // If user is not authenticated, show a sweet alert and redirect to login
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please login to continue!',
                confirmButtonText: 'OK',
                onClose: () => {
                    navigate('/signin');
                }
            });
        }
    }, [navigate]);

    useEffect(() => {
        const storedCartItems = localStorage.getItem('cartItems');
        if (storedCartItems) {
            setCartItems(JSON.parse(storedCartItems));
        }
    }, []);

    const handleRemoveFromCart = (itemId) => {
        const updatedCart = cartItems.filter((item) => item._id !== itemId);
        setCartItems(updatedCart);
        localStorage.setItem('cartItems', JSON.stringify(updatedCart));
    };

    const handleCheckout = () => {
        // Implement your checkout logic here
        // For now, let's just navigate back to the home page after removing items
        localStorage.removeItem('cartItems');
        setCartItems([]);
        navigate('/');
    };

    return (
        <>
            <header className="foi-header bg_header">
                <div className="container">
                    <div className="container bg_navbar">
                        <Navbar />
                    </div>
                </div>
            </header>
            <main className="page-about">
                <section style={{ paddingTop: "120px" }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <h3>Your Cart</h3>
                                {cartItems.length === 0 ? (
                                    <p>Your cart is empty.</p>
                                ) : (
                                    <div className="card">
                                        <div className="card-body">
                                            {cartItems.map((item, index) => (
                                                <div key={index} className="mb-4">
                                                    <h5>{item.testName}</h5>
                                                    <p>Price: ₹ {item.discountPrice.toFixed(2)}</p>
                                                    <button className="btn btn-danger btn-sm" onClick={() => handleRemoveFromCart(item._id)}>
                                                        Remove
                                                    </button>
                                                </div>
                                            ))}
                                            <hr />
                                            <div className="d-flex justify-content-between align-items-center">
                                                <h5>Total Items: {cartItems.length}</h5>
                                                <h5>Total Price: ₹ {cartItems.reduce((acc, item) => acc + item.discountPrice, 0).toFixed(2)}</h5>
                                            </div>
                                            <hr />
                                            <div className="d-flex justify-content-end">
                                                <button className="btn btn-primary" onClick={handleCheckout}>
                                                    Checkout
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    );
};

export default TestCartPage;
