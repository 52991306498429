import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Navbar from '../Navbar/Navbar';
import Footer from "../Navbar/Footer";
import Swal from 'sweetalert2';
import { API_URL } from '../../constants'; // Make sure to import the API_URL constant
import './CategoryMedicines.css';

const CustomerDetailsPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    
    // Extract state from location or set default values
    const { cartItems = [], totalPrice = 0 } = location.state || {};
    
    const [addresses, setAddresses] = useState([]);
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [newAddress, setNewAddress] = useState({
        addressLine1: '',
        fullName: '',
        contactNumber: '',
        addressLine2: '',
        city: '',
        state: '',
        postalCode: '',
        country: '',
        addressType: '' // Added addressType field
    });
    const [customerDetails, setCustomerDetails] = useState({
        fullName: '',
        contactNumber: '',
        email: '',
        dob: '',
        gender: '' // Added gender field
    });

    const states = [
        "Andhra Pradesh", "Arunachal Pradesh", "Assam", "Bihar", "Chhattisgarh", "Goa", "Gujarat", "Haryana", "Himachal Pradesh", "Jharkhand", "Karnataka",
        "Kerala", "Madhya Pradesh", "Maharashtra", "Manipur", "Meghalaya", "Mizoram", "Nagaland", "Odisha", "Punjab", "Rajasthan", "Sikkim",
        "Tamil Nadu", "Telangana", "Tripura", "Uttar Pradesh", "Uttarakhand", "West Bengal" // Add more as needed
    ];

    const [isLoading, setIsLoading] = useState(true);
    const [showAddForm, setShowAddForm] = useState(false);
    const [editAddress, setEditAddress] = useState(null);

    useEffect(() => {
        // Check if the user is authenticated (You can implement your own logic here)
        const isAuthenticated = sessionStorage.getItem('user'); // Example: Check if user data exists in session storage
        if (!isAuthenticated) {
            // If user is not authenticated, show a sweet alert and redirect to login
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please login to continue!',
                confirmButtonText: 'OK',
                onClose: () => {
                    navigate('/signin');
                }
            });
        }
    }, [navigate]);

    const user = JSON.parse(sessionStorage.getItem('user'));
    const userId = user ? user._id : null;

    useEffect(() => {
        const fetchAddresses = async () => {
            try {
                const response = await fetch(`${API_URL}/user/addresses/${userId}`);
                if (response.ok) {
                    const data = await response.json();
                    setAddresses(data.addresses);
                    setIsLoading(false);
                } else {
                    throw new Error('Failed to fetch addresses');
                }
            } catch (error) {
                console.error('Error fetching addresses:', error);
                Swal.fire("Error", "An error occurred while fetching addresses.", "error");
            }
        };

        if (userId) {
            fetchAddresses();
        } else {
            Swal.fire("Error", "Please login to continue!", "error").then(() => {
                navigate('/signin'); // Redirect to login page if user is not found
            });
        }
    }, [userId, navigate]);

    const handleAddressSelection = (address) => {
        setSelectedAddress(address);
    };

    const handleInputChange = (e) => {
        setNewAddress({ ...newAddress, [e.target.name]: e.target.value });
    };

    const handleCustomerDetailsChange = (e) => {
        setCustomerDetails({ ...customerDetails, [e.target.name]: e.target.value });
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        const requiredFields = ['fullName', 'contactNumber', 'addressLine1', 'city', 'state', 'postalCode', 'country', 'addressType']; // Added addressType as a required field
        const isAnyFieldEmpty = requiredFields.some(field => !newAddress[field].trim());
        if (isAnyFieldEmpty) {
            Swal.fire("Error", "Please fill in all required fields.", "error");
            return;
        }

        try {
            if (!selectedAddress) {
                // Adding a new address
                const response = await fetch(`${API_URL}/user/addresses`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ ...newAddress, customerId: userId })
                });
                if (response.ok) {
                    const data = await response.json();
                    setAddresses([...addresses, data.address]);
                    Swal.fire({
                        icon: 'success',
                        title: 'Details Submitted!',
                        text: 'Your details have been submitted successfully.',
                        confirmButtonText: 'OK',
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.href = '/customer-details'; // Redirect to customer-details page
                        }
                    });
                } else {
                    throw new Error('Failed to add address');
                }
            } else {
                // Editing an existing address
                const response = await fetch(`${API_URL}/user/addresses/${selectedAddress._id}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(newAddress)
                });
                if (response.ok) {
                    const updatedAddress = await response.json();
                    const updatedAddresses = addresses.map(address =>
                        address._id === updatedAddress._id ? updatedAddress : address
                    );
                    setAddresses(updatedAddresses);
                    Swal.fire({
                        icon: 'success',
                        title: 'Details Updated!',
                        text: 'Your details have been updated successfully.',
                        confirmButtonText: 'OK',
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.href = '/customer-details'; // Redirect to customer-details page
                        }
                    });

                } else {
                    throw new Error('Failed to update address');
                }
            }
        } catch (error) {
            console.error('Error submitting address:', error);
            Swal.fire("Error", "An error occurred while submitting the address.", "error");
        }
    };

    const handleAddNewAddress = () => {
        setShowAddForm(true);
        setEditAddress(null);
    };

    const handleEditAddress = (address) => {
        setEditAddress(address);
        setNewAddress(address);
        setShowAddForm(true);
        setSelectedAddress(address);
    };

    const handleDeleteAddress = async (addressId) => {
        try {
            const response = await fetch(`${API_URL}/user/addresses/${addressId}`, {
                method: 'DELETE'
            });
            if (response.ok) {
                const updatedAddresses = addresses.filter(address => address._id !== addressId);
                setAddresses(updatedAddresses);
                Swal.fire({
                    icon: 'success',
                    title: 'Address Deleted!',
                    text: 'The address has been deleted successfully.',
                    confirmButtonText: 'OK',
                });
            } else {
                throw new Error('Failed to delete address');
            }
        } catch (error) {
            console.error('Error deleting address:', error);
            Swal.fire("Error", "An error occurred while deleting the address.", "error");
        }
    };

    const handleCancelAddress = () => {
        setShowAddForm(false);
        setEditAddress(null);
        setNewAddress({
            addressLine1: '',
            fullName: '',
            contactNumber: '',
            addressLine2: '',
            city: '',
            state: '',
            postalCode: '',
            country: '',
            addressType: ''
        });
    };

    const handleCustomerDetailsSubmit = async (e) => {
        e.preventDefault();

        // Check if an address is selected
        if (!selectedAddress) {
            Swal.fire("Error", "Please select an address to continue.", "error");
            return;
        }

        const requiredFields = ['fullName', 'contactNumber', 'email', 'dob', 'gender'];
        const isAnyFieldEmpty = requiredFields.some(field => !customerDetails[field].trim());
        if (isAnyFieldEmpty) {
            Swal.fire("Error", "Please fill in all required fields.", "error");
            return;
        }

        try {
            // Store customer details and selected address in session
            sessionStorage.setItem('customerDetails', JSON.stringify(customerDetails));
            sessionStorage.setItem('selectedAddress', JSON.stringify(selectedAddress));
            console.log(customerDetails);
            console.log(selectedAddress);

            // Redirect to payment page with cart items and total price
            navigate('/payment', { state: { cartItems, totalPrice } });
        } catch (error) {
            console.error('Error submitting customer details:', error);
            Swal.fire("Error", "An error occurred while submitting customer details.", "error");
        }
    };
    
    

    return (
        <>
            <header className="foi-header bg_header">
                <div className="container">
                    <div className="container bg_navbar">
                        <Navbar />
                    </div>
                </div>
            </header>
            <main>
                <div style={{ paddingTop: "120px" }}>
                    <div className="container address-container" style={{ border: '2px solid #e0e0e0', padding: '20px', borderRadius: '20px' }}>
                        <h3 style={{ paddingTop: "20px", fontSize: '18px' }}>Customer/Patient Details</h3>
                        <form  className='card-body'>
                            <div className="row">
                                <div className="form-group col-md-4">
                                    <label htmlFor="fullName">Whom? <sup>*</sup></label>
                                    <input type="text" className="form-control" id="fullName" name="fullName" value={customerDetails.fullName} onChange={handleCustomerDetailsChange} placeholder="Whom" required />
                                </div>
                                <div className="form-group col-md-4">
                                    <label htmlFor="contactNumber">Contact Number <sup>*</sup></label>
                                    <input type="number" className="form-control" id="contactNumber" name="contactNumber" value={customerDetails.contactNumber} onChange={handleCustomerDetailsChange} placeholder="Contact Number" required />
                                </div>
                                <div className="form-group col-md-4">
                                    <label htmlFor="email">Email <sup>*</sup></label>
                                    <input type="email" className="form-control" id="email" name="email" value={customerDetails.email} onChange={handleCustomerDetailsChange} placeholder="Email" required />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-md-6">
                                    <label htmlFor="dob">Date Of Birth <sup>*</sup></label>
                                    <input type="date" className="form-control" id="dob" name="dob" value={customerDetails.dob} onChange={handleCustomerDetailsChange} required />
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="gender">Gender <sup>*</sup></label>
                                    <select className="form-control" id="gender" name="gender" value={customerDetails.gender} onChange={handleCustomerDetailsChange} required>
                                        <option value="">Select Gender</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                        <option value="Other">Other</option>
                                    </select>
                                </div>
                            </div>
                        </form>

                        <h5>Select an Address</h5>
                        {!showAddForm && (
                            <div className="row">
                                {addresses.map((address, index) => (
                                    <label key={index} htmlFor={`address-${index}`} className='col-lg-4 mb-4'>
                                        <div className={`card pricing-card active ${selectedAddress && selectedAddress._id === address._id ? 'selected-card' : ''}`} style={{ borderRadius: '20px', padding: '5px', alignItems: 'left', justifyContent: 'left', boxShadow: '5px 4px 8px rgba(1, 1, 1, 0.4)' }}>
                                            <div className="card-body" style={{ padding: '10px', textAlign: 'left' }}>
                                                <input
                                                    type="radio"
                                                    id={`address-${index}`}
                                                    name="address"
                                                    value={address._id}
                                                    onChange={() => handleAddressSelection(address)}
                                                    style={{ display: 'none' }} // Hide the radio button
                                                />
                                                <h3 className="mb-1" style={{ fontSize: '14px', color: "red" }}>
                                                    Address Type: <span>{address.addressType}</span>
                                                </h3>
                                                <h3 className="mb-1" style={{ fontSize: '14px' }}>
                                                    {address.addressLine1}, {address.city}, {address.state}, {address.postalCode}, {address.country}
                                                </h3>
                                                <div className="button-group">
                                                    <button type="button" className='btn btn-primary' style={{ padding: '10px', marginRight: '5px' }} onClick={(e) => { e.stopPropagation(); handleEditAddress(address); }}>Edit</button>
                                                    <button type="button" className='btn btn-secondary active' style={{ padding: '10px' }} onClick={(e) => { e.stopPropagation(); handleDeleteAddress(address._id); }}>Delete</button>
                                                </div>
                                            </div>
                                        </div>
                                    </label>
                                ))}
                            </div>
                        )}
                        <button className='btn btn-primary' onClick={handleAddNewAddress} style={{ marginBottom: '20px' }}>Add New Address</button>
                        <div className="card career-card mb-2">
                            {showAddForm && (
                                <form onSubmit={handleFormSubmit} className='card-body'>
                                    <h5>{editAddress ? 'Edit Address' : 'Add a New Address'}</h5>
                                    <div className="row">
                                        <div className="form-group col-md-4">
                                            <label htmlFor="addressType">Address Type <sup>*</sup></label>
                                            <select className="form-control" id="addressType" name="addressType" value={newAddress.addressType} onChange={handleInputChange} required>
                                                <option value="">Select Address Type</option>
                                                <option value="Home">Home</option>
                                                <option value="Work">Work</option>
                                                <option value="Other">Other</option>
                                            </select>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label htmlFor="fullName">Full Name <sup>*</sup></label>
                                            <input type="text" className="form-control" id="fullName" name="fullName" value={newAddress.fullName} onChange={handleInputChange} placeholder="Full Name" required />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label htmlFor="contactNumber">Contact Number <sup>*</sup></label>
                                            <input type="tel" className="form-control" id="contactNumber" name="contactNumber" value={newAddress.contactNumber} onChange={handleInputChange} placeholder="Contact Number" required />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-md-6">
                                            <label htmlFor="addressLine1">Address Line 1 <sup>*</sup></label>
                                            <textarea className="form-control" id="addressLine1" name="addressLine1" value={newAddress.addressLine1} onChange={handleInputChange} placeholder="Address Line 1" required />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label htmlFor="addressLine2">Address Line 2</label>
                                            <textarea className="form-control" id="addressLine2" name="addressLine2" value={newAddress.addressLine2} onChange={handleInputChange} placeholder="Address Line 2" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-md-3">
                                            <label htmlFor="city">City <sup>*</sup></label>
                                            <input type="text" className="form-control" id="city" name="city" value={newAddress.city} onChange={handleInputChange} placeholder="City" required />
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label htmlFor="state">State <sup>*</sup></label>
                                            <select className="form-control" id="state" name="state" value={newAddress.state} onChange={handleInputChange} required>
                                                <option value="">Select State</option>
                                                {states.map((state, index) => (
                                                    <option key={index} value={state}>{state}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label htmlFor="postalCode">Postal Code <sup>*</sup></label>
                                            <input type="number" className="form-control" id="postalCode" name="postalCode" value={newAddress.postalCode} onChange={handleInputChange} placeholder="Postal Code" required />
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label htmlFor="country">Country <sup>*</sup></label>
                                            <input type="text" className="form-control" id="country" name="country" value={newAddress.country} onChange={handleInputChange} placeholder="Country" required />
                                        </div>
                                    </div>
                                    <div className="button-group">
                                        <button className='btn btn-primary' type="submit">Save</button>
                                        <button type="button" className='btn btn-secondary' onClick={handleCancelAddress}>Cancel</button>
                                    </div>
                                </form>
                            )}
                        </div>
                        <center><button className='btn btn-primary' type="submit" onClick={handleCustomerDetailsSubmit}>Book</button></center>
                    </div>
                    
                </div>
            </main>
            <Footer />
        </>
    );
};

export default CustomerDetailsPage;
