// CategoryMedicines.js
import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import Navbar from '../Navbar/Navbar';
import Footer from "../Navbar/Footer";
import { API_URL } from '../../constants';
import './CategoryMedicines.css';

const CategoryMedicines = ({ cart, totalItemsInCart, addToCart, increaseQuantity, decreaseQuantity, resetCartCount }) => {
    const { category } = useParams();
    const [medicines, setMedicines] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const isAuthenticated = sessionStorage.getItem('user');
        if (!isAuthenticated) {
            navigate('/404');
        }
    }, [navigate]);

    useEffect(() => {
        const isAuthenticated = sessionStorage.getItem('user');
        if (!isAuthenticated) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please login to continue!',
                confirmButtonText: 'OK',
                onClose: () => {
                    navigate('/signin');
                }
            });
        }
    }, [navigate]);

    useEffect(() => {
        const fetchMedicines = async () => {
            try {
                const response = await fetch(`${API_URL}/pharmacyMedicine/products?category=${category}`);
                const data = await response.json();
                if (response.ok) {
                    setMedicines(data.medicines);
                    console.log(data.medicines);
                } else {
                    Swal.fire("Error", data.message, "error");
                }
            } catch (error) {
                Swal.fire("Error", "An error occurred while fetching medicines.", "error");
            }
        };

        fetchMedicines();
    }, [category]);

    return (
        <>
            <header className="foi-header bg_header">
                <div className="container">
                    <div className="container bg_navbar">
                        <Navbar />
                    </div>
                </div>
            </header>
            <main className="page-about">
                <section style={{ paddingTop: "150px" }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <h3 style={{ paddingBottom: "20px", textTransform: 'capitalize' }}>{category}</h3>
                                <div className="row">
                                    {medicines.map((medicine, index) => (
                                        <div key={index} className="col-lg-4 mb-4">
                                            <div className="card pricing-card_1 active" style={{ height: "100%", borderRadius: "20px", boxShadow: '0 0 15px rgba(24, 24, 24, .161)' }} >
                                                <div className="card-body d-flex flex-column">
                                                    <Link to={`/medicine-details/${medicine._id}`} style={{ textDecoration: 'none' }}>
                                                        {medicine.images && medicine.images.length > 0 && (
                                                            <img
                                                                src={`data:image/jpeg;base64,${medicine.images[0]}`}
                                                                alt={medicine.medicineName}
                                                                style={{ width: "100%", height: "200px", borderRadius: "10px 10px 0 0" }}
                                                            />
                                                        )}
                                                    </Link>
                                                    <h5 style={{ fontSize: '16px', color: '#262c67' }}>
                                                        {medicine.medicineName.length > 30
                                                            ? `${medicine.medicineName.substring(0, 30)}.....`
                                                            : medicine.medicineName}
                                                    </h5>
                                                    <h5 style={{ fontSize: '16px', color: '#000' }}>MRP <span style={{ textDecoration: "line-through", color: '#808080' }}>₹ {medicine.actualPrice.toFixed(2)}</span></h5>
                                                    <h5 style={{ fontSize: '16px', color: '#262c67' }}>₹ {medicine.discountPrice.toFixed(2)}</h5>
                                                    {cart[medicine._id] ? (
                                                        <div className="d-flex align-items-center mt-auto">
                                                            <button className="btn btn-primary" onClick={() => decreaseQuantity(medicine._id)}>-</button>
                                                            <span className="mx-2 btn btn-secondary" style={{ fontSize: '18px', fontWeight: '700' }}>{cart[medicine._id]}</span>
                                                            <button className="btn btn-primary" onClick={() => increaseQuantity(medicine._id)}>+</button>
                                                        </div>
                                                    ) : (
                                                        <button className="btn btn-primary btn-rounded mt-auto" onClick={() => addToCart(medicine._id)}>Add to Cart</button>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {totalItemsInCart > 0 && (
                    <Link to="/cart">
                        <div className="shopping-cart fixed-bottom-right">
                            <span className="cart-icon">
                                <img src={require('../assets/img/shopping-cart.gif')} alt="cart" width="45px" className="cart-icon-image" />
                                <span style={{ fontWeight: '700', fontSize: '24px', color:'#000' }}>{totalItemsInCart}</span>
                            </span>
                        </div>
                    </Link>
                )}
            </main>
            <Footer />
        </>
    );
}

export default CategoryMedicines;
