import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import Navbar from '../Navbar/Navbar';
import Footer from "../Navbar/Footer";

import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { API_URL } from '../../constants';

const FoodHome = () => {
    const navigate = useNavigate();
    const [categories, setCategories] = useState([]);
    const [restaurants, setRestaurants] = useState([]);
    const [numToShow, setNumToShow] = useState(10); // State for number of restaurants to show
    const [cartItems, setCartItems] = useState([]);

    useEffect(() => {
        const isAuthenticated = sessionStorage.getItem('user');
        if (!isAuthenticated) {
            navigate('/404');
        }
    }, [navigate]);

    useEffect(() => {
        const storedCartItems = JSON.parse(sessionStorage.getItem('cartItems')) || [];
        setCartItems(storedCartItems);
    }, []);

    useEffect(() => {
        const isAuthenticated = sessionStorage.getItem('user');
        if (!isAuthenticated) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please login to continue!',
                confirmButtonText: 'OK',
                onClose: () => {
                    navigate('/signin');
                }
            });
        }
    }, [navigate]);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await fetch(`${API_URL}/foodItems/categories`);
                if (!response.ok) {
                    throw new Error('Failed to fetch categories');
                }
                const data = await response.json();
                setCategories(data.categories);
            } catch (error) {
                console.error('Error fetching categories:', error);
                Swal.fire("Error!", "Failed to fetch categories", "error");
            }
        };

        fetchCategories();
    }, []);

    useEffect(() => {
        const fetchRestaurants = async () => {
            try {
                const response = await fetch(`${API_URL}/restaurants`);
                if (!response.ok) {
                    throw new Error('Failed to fetch restaurants');
                }
                const data = await response.json();
                setRestaurants(data.restaurants);
            } catch (error) {
                console.error('Error fetching restaurants:', error);
                Swal.fire("Error!", "Failed to fetch restaurants", "error");
            }
        };

        fetchRestaurants();
    }, []);

    const responsive = {
        0: { items: 1 },
        568: { items: 3 },
        1024: { items: 6 },
    };

    const handleCategoryClick = (categoryName) => {
        navigate(`/Category-Restaurants?category=${categoryName}`);
    };

    const handleRestaurantClick = (restaurantId) => {
        navigate(`/restaurants/${restaurantId}/menu`);
    };

    const renderCarouselItems = () => {
        return categories.map((category, index) => (
            <div className="item" key={index}>
                <div className="row">
                    <div className="col-md-12">
                        <div className="team-member-card team_card">
                            <div className="food_items" onClick={() => handleCategoryClick(category.categoryName)}>
                                <img src={`data:image/jpeg;base64,${category.categoryImage}`} alt={category.categoryName} className="team-member-avatar_1" />
                                <h6 style={{ color: '#626262', textTransform: 'capitalize' }} className="mb-0">{category.categoryName}</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ));
    };

    const renderRestaurantItems = () => {
        return restaurants.map((restaurant, index) => (
            <div className="item" key={index}>
                <div className="row">
                    <div className="col-md-12">
                        <div className="team-member-card team_card">
                            <div className="food_items" onClick={() => handleRestaurantClick(restaurant._id)}>
                                <img src={`data:image/jpeg;base64,${restaurant.thumbnailFood}`} alt={restaurant.restaurantName} className="team-member-avatar_1" />
                                <h6 style={{ color: '#626262', textTransform: 'capitalize' }} className="mb-0">{restaurant.restaurantName}</h6>
                                <p style={{ color: '#626262', textTransform: 'capitalize', fontSize: '12px' }} className="mb-0">{restaurant.restaurantDescription}</p>
                                <p style={{ color: '#626262', textTransform: 'capitalize', fontSize: '16px' }} className="mb-0"><img src={require('../assets/images/food/restaurant/star.png')} alt="star" width="14px" className="blog-post-thumbnail mr-md-1" />{restaurant.restaurantRating ?? '0.0'}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )).slice(0, numToShow); // Slice to show only `numToShow` restaurants
    };

    const renderRestaurantItemsContainer = () => {
        const rows = [];
        for (let i = 0; i < restaurants.length; i += 4) {
            rows.push(
                <div className="row mb-4" key={i}>
                    {restaurants.slice(i, i + 4).map((restaurant, index) => (
                        <div className="col-md-3" key={index}>
                            <div className="team-member-card team_card">
                                <div className="food_items" onClick={() => handleRestaurantClick(restaurant._id)}>
                                    <img src={`data:image/jpeg;base64,${restaurant.thumbnailFood}`} alt={restaurant.restaurantName} className="team-member-avatar_1" />
                                    <h6 style={{ color: '#626262', textTransform: 'capitalize' }} className="mb-0">{restaurant.restaurantName}</h6>
                                    <p style={{ color: '#626262', textTransform: 'capitalize', fontSize: '12px' }} className="mb-0">{restaurant.restaurantDescription}</p>
                                    <p style={{ color: '#626262', textTransform: 'capitalize', fontSize: '16px' }} className="mb-0">
                                        <img src={require('../assets/images/food/restaurant/star.png')} alt="star" width="14px" className="blog-post-thumbnail mr-md-1" />
                                        {restaurant.restaurantRating ?? '0.0'}
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            );
        }
        return rows;
    };

    const renderPrevButton = ({ isDisabled }) => (
        <button
            disabled={isDisabled}
            className="btn btn-food active alice-carousel-btn alice-carousel-prev-btn"
            style={{ position: 'absolute', top: 30, left: -20, zIndex: '9999' }}
        >
            <i class="fa-solid fa-angle-left"></i>
        </button>
    );

    const renderNextButton = ({ isDisabled }) => (
        <button
            disabled={isDisabled}
            className="btn btn-food active alice-carousel-btn alice-carousel-next-btn"
            style={{ position: 'absolute', top: 30, right: -20, zIndex: '9999' }}
        >
           <i class="fa-solid fa-angle-right"></i>
        </button>
    );

    const cartQuantity = cartItems.reduce((total, item) => total + item.quantity, 0);

      // Navigate to cart page
    const goToCartPage = () => {
        navigate('/Food-Cart'); // Assuming you have a cart page route
    };


    return (
        <>
            <header className="foi-header bg_header">
                <div className="container">
                    <div className="container bg_navbar">
                        <Navbar />
                    </div>
                </div>
            </header>
            <main className="page-about">
                <section style={{ paddingTop: "120px" }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div id="landingClientCarousel" className="carousel slide landing-client-carousel" data-ride="carousel">
                                    <div className="carousel-inner" role="listbox">
                                        <div className="carousel-item active">
                                            <div className="d-flex flex-wrap justify-content-center">
                                                <img style={{ borderRadius: '20px' }} src={require('../assets/img/food_1.png')} alt="Food" width="1000" className="img-fluid" />
                                            </div>
                                        </div>
                                        <div className="carousel-item">
                                            <div className="d-flex flex-wrap justify-content-center">
                                                <img style={{ borderRadius: '20px' }} src={require('../assets/img/food_2.png')} alt="Food" width="1000" className="img-fluid" />
                                            </div>
                                        </div>
                                        <div className="carousel-item">
                                            <div className="d-flex flex-wrap justify-content-center">
                                                <img style={{ borderRadius: '20px' }} src={require('../assets/img/food_3.png')} alt="Food" width="1000" className="img-fluid" />
                                            </div>
                                        </div>
                                        <div className="carousel-item">
                                            <div className="d-flex flex-wrap justify-content-center">
                                                <img style={{ borderRadius: '20px' }} src={require('../assets/img/food_4.png')} alt="Food" width="1000" className="img-fluid" />
                                            </div>
                                        </div>
                                    </div>
                                    <ol className="carousel-indicators">
                                        <li data-target="#landingClientCarousel" data-slide-to="0" className="active"></li>
                                        <li data-target="#landingClientCarousel" data-slide-to="1"></li>
                                        <li data-target="#landingClientCarousel" data-slide-to="2"></li>
                                        <li data-target="#landingClientCarousel" data-slide-to="3"></li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section style={{ paddingTop: "20px" }}>
                    <div className="container">
                        <h2>What's on your mind?</h2>
                        <p className="text-muted mb-5" style={{ fontSize: '16px' }}>Savor the excellence of our food service, where delectable flavors meet impeccable service for an unparalleled dining experience.</p>
                        <AliceCarousel
                            mouseTracking
                            items={renderCarouselItems()}
                            responsive={responsive}
                            renderPrevButton={renderPrevButton}
                            renderNextButton={renderNextButton}
                            disableDotsControls={true}
                        />
                    </div>
                </section>
                <section style={{ paddingTop: '50px', paddingBottom: '50px' }}>
                    <div className="container" style={{ position: 'relative', borderBottom: '3px solid #f0f0f5' }}>
                        <h2>Top restaurant chains in Hyderabad</h2>
                        <p className="text-muted mb-5" style={{ fontSize: '16px' }}>Hyderabad boasts a diverse culinary landscape with a myriad of restaurant chains that cater to various palates. From traditional Hyderabadi delicacies to global cuisines, these top restaurant chains in the city offer a delightful dining experience, blending flavors, ambiance, and hospitality to create memorable moments for patrons.</p>
                        <AliceCarousel
                            mouseTracking
                            items={renderRestaurantItems()}
                            responsive={responsive}
                            renderPrevButton={renderPrevButton}
                            renderNextButton={renderNextButton}
                            disableDotsControls={true}
                        />
                    </div>
                </section>
                <section style={{ paddingTop: '50px', paddingBottom: '50px' }}>
                    <div className="container" style={{ position: 'relative' }}>
                        <h2>Restaurants with online food delivery in Hyderabad</h2>
                        {renderRestaurantItemsContainer().slice(0, numToShow)} {/* Slice to show only `numToShow` restaurants */}
                        {restaurants.length > numToShow && (
                            <button
                                className="btn btn-primary mt-3"
                                onClick={() => setNumToShow(num => num + 10)}
                            >
                                Load More
                            </button>
                        )}
                    </div>
                </section>
                {/* Show the cart icon with total number of items in the cart */}
                {cartQuantity > 0 && (
                    <div className="shopping-cart fixed-bottom-right cart_shop" onClick={goToCartPage}>
                        <Link  className="cart-icon">
                            <img src={require('../assets/img/shopping-cart.gif')} alt="cart" width="45px" className="blog-post-thumbnail" />
                            <span className="cart_shop" style={{ fontWeight: '700', fontSize: '24px', color: '#000' }}>{cartQuantity}</span>
                        </Link>
                    </div>
                )}
            </main>
                <Footer />

        </>
    );
};

export default FoodHome;
