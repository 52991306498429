import React, { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

const Navbar = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [profilePhoto, setProfilePhoto] = useState('');
    const navigate = useNavigate();
    const [logoutTimer, setLogoutTimer] = useState(null);

    useEffect(() => {
        const user = sessionStorage.getItem('user');
        if (user) {
            setIsLoggedIn(true);
            const userProfile = JSON.parse(user);
            if (userProfile && userProfile.profilePhoto) {
                setProfilePhoto(userProfile.profilePhoto);
            }
        }

        const events = ['mousemove', 'keydown', 'click'];

        const handleUserActivity = () => {
            if (isLoggedIn) {
                resetLogoutTimer();
            }
        };

        events.forEach(event => {
            window.addEventListener(event, handleUserActivity);
        });

        return () => {
            events.forEach(event => {
                window.removeEventListener(event, handleUserActivity);
            });
        };
    }, [isLoggedIn]);

    const handleSignOut = useCallback(() => {
        const user = sessionStorage.getItem('user');
        if (user) {
            sessionStorage.removeItem('user');
            setIsLoggedIn(false);
            if (logoutTimer) {
                clearTimeout(logoutTimer);
            }
            navigate('/signin', { state: { loggedOut: true } });
        }
    }, [navigate, logoutTimer]);

    const resetLogoutTimer = useCallback(() => {
        if (logoutTimer) {
            clearTimeout(logoutTimer);
        }
        const timer = setTimeout(handleSignOut, 50 * 60 * 1000); // 50 minutes
        setLogoutTimer(timer);
    }, [handleSignOut, logoutTimer]);

    return (
        <nav className="navbar navbar-expand-lg navbar-light foi-navbar">
            <Link className="navbar-brand" to='/'>
                <img src="../../../assets/images/logo-1.png" alt="VC" />
            </Link>
            <button className="navbar-toggler d-lg-none" type="button" data-toggle="collapse" data-target="#collapsibleNavId" aria-controls="collapsibleNavId" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="collapsibleNavId">

                {isLoggedIn ? (
                    <>
                        <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
                            <li className="nav-item active">
                                <Link className="nav-link nav_1" style={{ fontWeight: "800" }} to='/services-home'>Home<span className="sr-only">(current)</span></Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link nav_1" style={{ fontWeight: "800" }} to='/about'>About</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link nav_1" style={{ fontWeight: "800" }} to='/features'>Features</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link nav_1" style={{ fontWeight: "800" }} to='/contact'>Contact</Link>
                            </li>
                        </ul>
                        <ul className="navbar-nav mt-2 mt-lg-0">
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle nav_1" style={{ fontWeight: "800" }} href="#" id="settingsMenu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {profilePhoto ? (
                                        <img src={`data:image/jpeg;base64,${profilePhoto}`} alt="Profile" style={{ width: "50px", height: "50px", borderRadius: "50%", marginRight: '10px' }} />
                                    ) : (
                                        <FontAwesomeIcon icon={faCog} className="mr-1" />
                                    )}
                                </a>
                                <div className="dropdown-menu" aria-labelledby="settingsMenu">
                                    <Link className="dropdown-item nav_1" style={{ fontWeight: "600" }} to='/services-home' >My Profile</Link>
                                    <Link className="dropdown-item nav_1" style={{ fontWeight: "600" }} to='/services-home'>Privacy policy</Link>
                                    <Link className="dropdown-item nav_1" style={{ fontWeight: "600" }} to='/orders'>My Orders</Link>
                                    <div className="dropdown-divider"></div>
                                    <Link onClick={handleSignOut} className="dropdown-item" to={'/signin'} style={{ fontWeight: "600", color:'red' }}><FontAwesomeIcon icon={faSignOutAlt} className="mr-1" /> Sign Out</Link>
                                </div>
                            </li>
                        </ul>
                    </>
                ) : (
                    <>
                        <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
                            <li className="nav-item active">
                                <Link className="nav-link nav_1" style={{ fontWeight: "800" }} to='/'>Home <span className="sr-only">(current)</span></Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link nav_1" style={{ fontWeight: "800" }} to='/about'>About</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link nav_1" style={{ fontWeight: "800" }} to='/features'>Features</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link nav_1" style={{ fontWeight: "800" }} to='/services'>Services</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link nav_1" style={{ fontWeight: "800" }} to='/contact'>Contact</Link>
                            </li>
                        </ul>
                        <ul className="navbar-nav mt-2 mt-lg-0">
                            <li className="nav-item mr-2 mb-3 mb-lg-0">
                                <Link className="btn btn-primary" to='/signup'>Sign Up</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="btn btn-primary" to='/signin'>Login</Link>
                            </li>
                        </ul>
                    </>
                )}
            </div>
        </nav>
    );
};

export default Navbar;
