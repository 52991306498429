import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import Navbar from '../../Navbar/Navbar';
import Footer from '../../Navbar/Footer';
import { API_URL } from '../../../constants';

const CategoryTestsPage = () => {
    const navigate = useNavigate();
    const { centerId, centerName, categoryName } = useParams();
    const [tests, setTests] = useState([]);
    const [cartItems, setCartItems] = useState([]);
    const [isCartOpen, setIsCartOpen] = useState(false);

    useEffect(() => {
        // Check if the user is authenticated (You can implement your own logic here)
        const isAuthenticated = sessionStorage.getItem('user'); // Example: Check if user data exists in session storage
        if (!isAuthenticated) {
            // If user is not authenticated, redirect to the No Access page
            navigate('/404');
        }
    }, [navigate]);


    useEffect(() => {
        // Check if the user is authenticated (You can implement your own logic here)
        const isAuthenticated = sessionStorage.getItem('user'); // Example: Check if user data exists in session storage
        if (!isAuthenticated) {
            // If user is not authenticated, show a sweet alert and redirect to login
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please login to continue!',
                confirmButtonText: 'OK',
                onClose: () => {
                    navigate('/signin');
                }
            });
        }
    }, [navigate]);

    useEffect(() => {
        const fetchTests = async () => {
            try {
                const response = await fetch(`${API_URL}/diagnostic-categories/${centerId}/${encodeURIComponent(centerName)}/${encodeURIComponent(categoryName)}`);
                const data = await response.json();
                console.log(data);
                setTests(data);
            } catch (error) {
                console.error('Error fetching lab tests:', error);
            }
        };

        fetchTests();
    }, [centerId, centerName, categoryName]);

    useEffect(() => {
        const storedCartItems = localStorage.getItem('cartItems');
        if (storedCartItems) {
            setCartItems(JSON.parse(storedCartItems));
        }
    }, []);

    const countTotalTests = (packages) => {
        let totalTests = 0;
        packages.forEach(pkg => {
            totalTests += pkg.packageDetails.length;
        });
        return totalTests;
    };

    const handleAddToCart = (test) => {
        const updatedCart = [...cartItems, test];
        setCartItems(updatedCart);
        localStorage.setItem('cartItems', JSON.stringify(updatedCart));
    };

    const handleRemoveFromCart = (testId) => {
        const updatedCart = cartItems.filter((item) => item._id !== testId);
        setCartItems(updatedCart);
        localStorage.setItem('cartItems', JSON.stringify(updatedCart));
    };

    const handleOpenCart = () => {
        navigate("/testcart", { state: { cartItems } });
    };

    const isItemInCart = (testId) => {
        return cartItems.some((item) => item._id === testId);
    };

    const totalItemsInCart = cartItems.length;

    return (
        <>
            <header className="foi-header bg_header">
                <div className="container">
                    <div className="container bg_navbar">
                        <Navbar />
                    </div>
                </div>
            </header>
            <main className="page-about">
                <section style={{ paddingTop: "120px" }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div id="landingClientCarousel" className="carousel slide landing-client-carousel" data-ride="carousel">
                                    <div className="carousel-inner" role="listbox">
                                        <div className="carousel-item active">
                                            <div className="d-flex flex-wrap justify-content-center">
                                                <img style={{ borderRadius: '20px' }} src={require('../../assets/img/slide-1.png')} alt="Slide 1" width="1000" className="img-fluid" />
                                            </div>
                                        </div>
                                        <div className="carousel-item">
                                            <div className="d-flex flex-wrap justify-content-center">
                                                <img style={{ borderRadius: '20px' }} src={require('../../assets/img/slide-2.png')} alt="Slide 2" width="1000" className="img-fluid" />
                                            </div>
                                        </div>
                                        <div className="carousel-item">
                                            <div className="d-flex flex-wrap justify-content-center">
                                                <img style={{ borderRadius: '20px' }} src={require('../../assets/img/slide-3.png')} alt="Slide 3" width="1000" className="img-fluid" />
                                            </div>
                                        </div>
                                        <div className="carousel-item">
                                            <div className="d-flex flex-wrap justify-content-center">
                                                <img style={{ borderRadius: '20px' }} src={require('../../assets/img/slide-4.png')} alt="Slide 4" width="1000" className="img-fluid" />
                                            </div>
                                        </div>
                                    </div>
                                    <ol className="carousel-indicators">
                                        <li data-target="#landingClientCarousel" data-slide-to="0" className="active"></li>
                                        <li data-target="#landingClientCarousel" data-slide-to="1"></li>
                                        <li data-target="#landingClientCarousel" data-slide-to="2"></li>
                                        <li data-target="#landingClientCarousel" data-slide-to="3"></li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section style={{ paddingTop: '20px' }}>
                    <div className="container">
                        <h3 style={{ paddingBottom: '20px' }}>
                            Lab Tests under {decodeURIComponent(categoryName)} at {decodeURIComponent(centerName)}
                        </h3>
                        <div className="row">
                            {tests.map((test, index) => (
                                <div key={index} className="col-lg-4 mb-4">
                                    <div className="card pricing-card active w-100 h-100" style={{ padding: '0', borderRadius: '10px', boxShadow: '0 0 15px rgba(24, 24, 24, .161)' }}>
                                        <div className="card-body d-flex flex-column text-left h-100">
                                            <h6>{test.testName}</h6>
                                            <p className="text-primary" style={{ marginBottom: '5px', fontWeight: '700', fontSize: '20px' }}>₹ {test.discountPrice.toFixed(2)}<span style={{ fontSize: '14px', color: '#000' }}> onwards</span></p>
                                            <p style={{ marginBottom: '10px' }}>{test.testName} with Smart Report</p>
                                            <p style={{ color: '#000', fontWeight: '700', marginBottom: '20px' }}> {countTotalTests(test.selectedPackages)} Tests</p>
                                            {test.selectedPackages.slice(0, 1).map((pkg, pkgIndex) => (
                                                <div key={pkgIndex} style={{ marginBottom: '10px' }}>
                                                    <ul>
                                                        {pkg.packageDetails.slice(0, 2).map((detail, detailIndex) => (
                                                            <li style={{ fontSize: '14px' }} key={detailIndex}>{detail}</li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            ))}
                                            <div className="mt-auto d-flex justify-content-between">
                                                {isItemInCart(test._id) ? (
                                                    <button className="btn btn-danger btn-rounded" style={{ width: '45%' }} onClick={() => handleRemoveFromCart(test._id)}>
                                                        Remove
                                                    </button>
                                                ) : (
                                                    <button className="btn btn-primary btn-rounded" style={{ width: '45%' }} onClick={() => handleAddToCart(test)}>
                                                        Add to Cart
                                                    </button>
                                                )}
                                                <Link to={`/details/${test._id}`} className="btn btn-secondary btn-rounded" style={{ width: '45%', border: '1px solid #95979b' }}>
                                                    Details
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
                {totalItemsInCart > 0 && (
                    <div className="shopping-cart fixed-bottom-right">
                        <span className="cart-icon" onClick={handleOpenCart} style={{ fontWeight: '700', fontSize: '20px' }}>
                            <img src={require("../../assets/img/shopping-cart.gif")} alt="cart" width="45px" />
                            {totalItemsInCart}
                        </span>
                    </div>
                )}
            </main>
            <Footer />
        </>
    );
};

export default CategoryTestsPage;
