import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import Navbar from '../../Navbar/Navbar';
import Footer from '../../Navbar/Footer';
import { API_URL } from '../../../constants';

const TestDetailsPage = () => {
    const navigate = useNavigate();
    const { testId } = useParams();
    const [testDetails, setTestDetails] = useState(null);
    const [activeFAQ, setActiveFAQ] = useState(null);
    const [cartItems, setCartItems] = useState([]);
    const [isCartOpen, setIsCartOpen] = useState(false);

    useEffect(() => {
        // Check if the user is authenticated (You can implement your own logic here)
        const isAuthenticated = sessionStorage.getItem('user'); // Example: Check if user data exists in session storage
        if (!isAuthenticated) {
            // If user is not authenticated, redirect to the No Access page
            navigate('/404');
        }
    }, [navigate]);


    useEffect(() => {
        // Check if the user is authenticated (You can implement your own logic here)
        const isAuthenticated = sessionStorage.getItem('user'); // Example: Check if user data exists in session storage
        if (!isAuthenticated) {
            // If user is not authenticated, show a sweet alert and redirect to login
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please login to continue!',
                confirmButtonText: 'OK',
                onClose: () => {
                    navigate('/signin');
                }
            });
        }
    }, [navigate]);

    useEffect(() => {
        const fetchTestDetails = async () => {
            try {
                const response = await fetch(`${API_URL}/test-details/${testId}`);
                const data = await response.json();
                console.log(data);
                setTestDetails(data);
            } catch (error) {
                console.error('Error fetching test details:', error);
            }
        };

        fetchTestDetails();
    }, [testId]);

    useEffect(() => {
        const storedCartItems = localStorage.getItem('cartItems');
        if (storedCartItems) {
            setCartItems(JSON.parse(storedCartItems));
        }
    }, []);

    const handleFAQClick = (index) => {
        setActiveFAQ(index === activeFAQ ? null : index);
    };

    const handleAddToCart = () => {
        const updatedCart = [...cartItems, testDetails];
        setCartItems(updatedCart);
        setIsCartOpen(true);
        localStorage.setItem('cartItems', JSON.stringify(updatedCart));
    };

    const handleRemoveFromCart = () => {
        const updatedCart = cartItems.filter((item) => item._id !== testDetails._id);
        setCartItems(updatedCart);
        localStorage.setItem('cartItems', JSON.stringify(updatedCart));
    };

    const handleOpenCart = () => {
        navigate("/testcart", { state: { cartItems } });
    };

    if (!testDetails) {
        return <div>Loading...</div>;
    }

    const isItemInCart = cartItems.some((item) => item._id === testDetails._id);

    const totalItemsInCart = cartItems.length;

    // Calculate total number of tests in all selected packages
    let totalTestsCount = 0;
    testDetails.selectedPackages.forEach(pkg => {
        totalTestsCount += pkg.packageDetails.length;
    });

    return (
        <>
            <header className="foi-header bg_header">
                <div className="container">
                    <div className="container bg_navbar">
                        <Navbar />
                    </div>
                </div>
            </header>

            <section className="">
                <section>
                    <section class="py-5 mb-5 Bg_1">
                        <div class="container" style={{ paddingTop: '100px' }}>
                            <h6>{testDetails.testName}</h6>
                            <div>
                                <p className="text-primary" style={{ marginBottom: '5px', fontWeight: '700', fontSize: '20px', display: 'inline-block', marginRight: '20px' }}>
                                    ₹ {testDetails.discountPrice.toFixed(2)}/-
                                </p>

                                <p className="text-muted" style={{ marginBottom: '5px', fontWeight: '700', fontSize: '20px', display: 'inline-block', marginLeft: '10px' }}>
                                    <span style={{ textDecoration: 'line-through', marginRight: '20px' }}>₹ {testDetails.actualPrice.toFixed(2)}</span>
                                    <button type="button" className="btn btn-sm btn-primary btn-rounded active" style={{ padding: '10px' }}>
                                        {testDetails.offerDiscount}% OFF
                                    </button>
                                </p>
                            </div>

                            <div class="row">
                                <div class="col-lg-12 ">
                                    <div class="card pricing-card_1 active" style={{ background: "#ebebeb" }}>
                                        <div class="card-body">
                                            <section>
                                                <section class="contact-content" style={{ marginBottom: '0px', justifyContent: "center", alignItems: "center", borderBottom: "1px solid #e0e0e0", }}>
                                                    <div class="contact-widget media">
                                                        <img src={require("../../assets/img/home.png")} width="25px" alt="monitor" />
                                                        <div class="media-body">
                                                            <h6 class="widget-title">Home Sample Collection</h6>
                                                        </div>
                                                    </div>
                                                    <div class="contact-widget media">
                                                        <div class="media-body">
                                                            <h6 class="widget-title">Prescription Not Required</h6>
                                                        </div>
                                                    </div>
                                                </section>
                                                <section class="contact-content" style={{ marginBottom: '0px', justifyContent: "center", alignItems: "center", paddingTop: "10px", }}>
                                                    <div class="media-body">
                                                        <li class="widget-title">{testDetails.gender}</li>
                                                    </div>
                                                    <div class="media-body">
                                                        <li class="widget-title">{testDetails.ageGroup}</li>
                                                    </div>
                                                    <div class="media-body">
                                                        <li class="widget-title">Fasting: 10-12 Hrs</li>
                                                    </div>
                                                    <div class="media-body">
                                                        <li class="widget-title">Reports in 8-10 Hrs</li>
                                                    </div>
                                                </section>
                                            </section><br />
                                            <div className="mt-auto d-flex justify-content-center">
                                                {isItemInCart ? (
                                                    <button className="btn btn-danger btn-rounded" onClick={handleRemoveFromCart}>
                                                        Remove from Cart
                                                    </button>
                                                ) : (
                                                    <button className="btn btn-primary btn-rounded" onClick={handleAddToCart}>
                                                        Add to Cart
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>
                <main className="page-blog-post">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <section className="blog-post-author-info-section">
                                    <div className="row">
                                        <div className="col-lg-10 offset-lg-1">
                                            <div className="blog-post-author-info media">
                                                <div className="media-body">
                                                    <h5>About the Package</h5>
                                                    <p className="author-description" style={{ textAlign: "justify" }}>
                                                        {testDetails.description}
                                                    </p>
                                                    <br />
                                                    <p className="author-description" style={{ textAlign: "justify" }}>
                                                        {testDetails.prerequisites}
                                                    </p>
                                                    <br />
                                                    <h6>{totalTestsCount} Lab Tests<span style={{ fontWeight: '400' }}> in this package</span></h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </main>
                <section>
                    <div className="container">
                        <div className="row">
                            {testDetails.selectedPackages.map((pkg, index) => (
                                <div key={index} className="col-md-6 mb-4">
                                    <div className="card landing-faq-card card-header bg-white" style={{ borderRadius: "20px" }}>
                                        <button className="btn btn-link_1 d-flex align-items-center" type="button" onClick={() => handleFAQClick(index)}>
                                            <h6 className="mb-0" style={{ fontSize: "14px", textAlign: 'left' }}>{pkg.packageName}</h6>
                                            <i className={`far fa-plus-square ml-auto ${index === activeFAQ ? "rotate-icon" : ""}`}></i>
                                        </button>
                                        <div className={`collapse ${index === activeFAQ ? "show" : ""}`} aria-labelledby={`faqTitle${index}`}>
                                            <div className="card-body">
                                                <ul>
                                                    {pkg.packageDetails.map((detail, idx) => (
                                                        <li key={idx} style={{ fontSize: "14px", color: "#989898" }}>{detail}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>

                {totalItemsInCart > 0 && (
                    <div className="shopping-cart fixed-bottom-right">
                        <span className="cart-icon" onClick={handleOpenCart} style={{ fontWeight: '700', fontSize: '20px' }}>
                            <img src={require("../../assets/img/shopping-cart.gif")} alt="cart" width="45px" />
                            {totalItemsInCart}
                        </span>
                    </div>
                )}
            </section>
            <Footer />
        </>
    );
};

export default TestDetailsPage;
