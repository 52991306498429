import React, { useEffect, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import Navbar from '../Navbar/Navbar';
import Footer from "../Navbar/Footer";

import { API_URL } from '../../constants';
import '@fortawesome/fontawesome-free/css/all.min.css'; // Import Font Awesome CSS
import './Food.css';
import Swal from 'sweetalert2'; // Import SweetAlert

// Ensure that the paths are correct based on your folder structure
import vegImage from '../assets/images/food/veg.png';
import nonVegImage from '../assets/images/food/non-veg.png';

const MenuItemsPage = () => {
    const { restaurantId } = useParams();
    const navigate = useNavigate();
    const [menuItems, setMenuItems] = useState([]);
    const [restaurantName, setRestaurantName] = useState('');
    const [openSections, setOpenSections] = useState({});
    const [cartItems, setCartItems] = useState(JSON.parse(sessionStorage.getItem('cartItems')) || []);
    useEffect(() => {
        const isAuthenticated = sessionStorage.getItem('user');
        if (!isAuthenticated) {
            navigate('/404');
        }
    }, [navigate]);

    useEffect(() => {
        const isAuthenticated = sessionStorage.getItem('user');
        if (!isAuthenticated) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please login to continue!',
                confirmButtonText: 'OK',
                onClose: () => {
                    navigate('/signin');
                }
            });
        }
    }, [navigate]);

    useEffect(() => {
        const fetchRestaurantDetails = async () => {
            try {
                const response = await fetch(`${API_URL}/restaurants/${restaurantId}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch restaurant details');
                }
                const data = await response.json();
                console.log("Restaurant data:", data); // Debug log
                setRestaurantName(data.restaurantName);
            } catch (error) {
                console.error('Error fetching restaurant details:', error);
            }
        };

        const fetchMenuItems = async () => {
            try {
                const response = await fetch(`${API_URL}/restaurants/${restaurantId}/menu`);
                if (!response.ok) {
                    throw new Error('Failed to fetch menu items');
                }
                const data = await response.json();
                console.log("Menu items data:", data);
                setMenuItems(data.menuItems);
                if (data.menuItems.length > 0) {
                    setRestaurantName(data.menuItems[0].restaurantName);
                }
            } catch (error) {
                console.error('Error fetching menu items:', error);
            }
        };

        fetchRestaurantDetails();
        fetchMenuItems();
    }, [restaurantId]);

    // Function to group menu items by category
    const groupMenuItemsByCategory = (items) => {
        const groupedItems = {};
        items.forEach(item => {
            const category = item.category;
            if (!groupedItems[category]) {
                groupedItems[category] = [];
            }
            groupedItems[category].push(item);
        });
        return groupedItems;
    };

    // Group menu items by category
    const groupedMenuItems = groupMenuItemsByCategory(menuItems);

    // Function to toggle the collapse state of a section
    const toggleSection = (index) => {
        setOpenSections(prevState => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    };

    // Function to add item to cart with restaurant check
    const addToCart = (item) => {
        const existingRestaurantId = sessionStorage.getItem('restaurantId');

        if (existingRestaurantId && existingRestaurantId !== restaurantId) {
            // Prompt user to clear existing items from cart
            Swal.fire({
                title: 'Remove existing items?',
                text: 'Adding items from a different restaurant will clear your current cart. Do you want to proceed?',
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, clear it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    // Clear cart items and add the new item
                    const newCartItems = [{ ...item, quantity: 1, itemId: item._id }];
                    setCartItems(newCartItems);
                    updateCartInSessionStorage(newCartItems);
                    sessionStorage.setItem('restaurantId', restaurantId);
                    Swal.fire(
                        'Cleared!',
                        'Your previous items have been removed from the cart.',
                        'success'
                    );
                }
            });
        } else {
            // Add item to cart without clearing
            const existingItemIndex = cartItems.findIndex(cartItem => cartItem.itemId === item._id);
            if (existingItemIndex !== -1) {
                const updatedCartItems = [...cartItems];
                updatedCartItems[existingItemIndex] = { ...updatedCartItems[existingItemIndex], quantity: updatedCartItems[existingItemIndex].quantity + 1 };
                setCartItems(updatedCartItems);
                updateCartInSessionStorage(updatedCartItems);
            } else {
                const newCartItems = [...cartItems, { ...item, quantity: 1, itemId: item._id }];
                setCartItems(newCartItems);
                updateCartInSessionStorage(newCartItems);
            }
            sessionStorage.setItem('restaurantId', restaurantId);
        }
    };


    // Function to update cart items in sessionStorage
    const updateCartInSessionStorage = (cartItems) => {
        sessionStorage.setItem('cartItems', JSON.stringify(cartItems));
    };

    // Function to increase quantity of an item in cart
    const increaseQuantity = (itemId) => {
        const updatedCartItems = cartItems.map(item =>
            item.itemId === itemId ? { ...item, quantity: item.quantity + 1 } : item
        );
        setCartItems(updatedCartItems);
        updateCartInSessionStorage(updatedCartItems); // Update sessionStorage
    };

    // Function to decrease quantity of an item in cart
    const decreaseQuantity = (itemId) => {
        const itemIndex = cartItems.findIndex(item => item.itemId === itemId);
        if (itemIndex !== -1) {
            const currentItem = cartItems[itemIndex];
            if (currentItem.quantity === 1) {
                // Show SweetAlert confirmation dialog
                Swal.fire({
                    title: 'Are you sure?',
                    text: "Do you want to remove this item from the cart?",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, remove it!'
                }).then((result) => {
                    if (result.isConfirmed) {
                        // Remove item from cart
                        const updatedCartItems = [...cartItems];
                        updatedCartItems.splice(itemIndex, 1);
                        setCartItems(updatedCartItems);
                        updateCartInSessionStorage(updatedCartItems); // Update sessionStorage
                        Swal.fire(
                            'Removed!',
                            'The item has been removed from the cart.',
                            'success'
                        );
                    }
                });
            } else {
                // Decrease quantity
                const updatedCartItems = cartItems.map(item =>
                    item.itemId === itemId ? { ...item, quantity: item.quantity - 1 } : item
                );
                setCartItems(updatedCartItems.filter(item => item.quantity > 0));
                updateCartInSessionStorage(updatedCartItems); // Update sessionStorage
            }
        }
    };

    // Calculate total quantity of items in cart
    const cartQuantity = cartItems.reduce((total, item) => total + item.quantity, 0);

    // Navigate to cart page
    const goToCartPage = () => {
        navigate('/Food-Cart'); // Assuming you have a cart page route
    };

    return (
        <>
            <header className="foi-header bg_header">
                <div className="container">
                    <div className="container bg_navbar">
                        <Navbar />
                    </div>
                </div>
            </header>
            <main className="page-blog-post" style={{ paddingTop: '100px' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <section className="page-header" style={{padding:'20px'}}>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb foi-breadcrumb">
                                        <li className="breadcrumb-item"><Link to={'/food-home'}>Home</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page" style={{textTransform:'capitalize'}}>{restaurantName}</li>
                                    </ol>
                                </nav>
                            </section>
                            <section className="related-posts-section" style={{paddingLeft:'30px', borderBottom: '2px dotted #8094a3', marginBottom: '20px', textTransform:'capitalize' }}>
                                <div className="row" style={{ paddingBottom: "20px" }}>
                                    <div className="col-md-8 related-post">
                                        <h6>{restaurantName}</h6>
                                    </div>
                                </div>
                            </section>
                            <section className="mb-5 container">
                                <div className="row">
                                    {/* Map through categories and render each */}
                                    {Object.keys(groupedMenuItems).map((category, index) => (
                                        <div className="col-lg-12" key={index}>
                                            <div className="card mb-3 landing-faq-card" style={{ borderRadius: '20px' }}>
                                                <div className="card-header bg-white" style={{ borderRadius: '20px', padding: "20px" }}>
                                                    <div
                                                        className="d-flex align-items-center"
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => toggleSection(index)}
                                                    >
                                                        <h6 className="mb-0" style={{ textTransform: 'capitalize' }}>{category}</h6>
                                                        <i className={`fas ml-auto ${openSections[index] ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
                                                    </div>
                                                </div>
                                                <div className={`collapse-content ${openSections[index] ? 'show' : ''}`}>
                                                    <div className="card-body">
                                                        {/* Render items within the category */}
                                                        {groupedMenuItems[category].map((item, itemIndex) => (
                                                            <div key={itemIndex} style={{ borderBottom: '1px solid #d3d3d3', marginBottom: '20px' }}>
                                                                <div className='container'>
                                                                    <div className='row' style={{ paddingBottom: '15px' }}>
                                                                        <div className='col-md-8'>
                                                                            <h6>
                                                                                <img
                                                                                    src={
                                                                                        item.type === "Veg"
                                                                                            ? vegImage
                                                                                            : nonVegImage
                                                                                    }
                                                                                    alt={item.type === "Veg" ? "Veg" : "Non-Veg"}
                                                                                    width="15px"
                                                                                />
                                                                                <span style={{ marginLeft: '5px' }}>{item.itemName}</span>
                                                                            </h6>
                                                                            <p style={{ textTransform: 'capitalize' }}>{item.description}</p>
                                                                            <p>
                                                                                <span style={{ fontFamily: "sans-serif" }}>₹</span>
                                                                                {item.price.toFixed(2)}
                                                                            </p>
                                                                            {/* Render add button or quantity controls */}
                                                                            {cartItems.find(cartItem => cartItem.itemId === item._id) ? (
                                                                                <div>
                                                                                    <button className="btn btn-food" style={{ padding: '10px' }} onClick={() => decreaseQuantity(item._id)}>
                                                                                        -
                                                                                    </button>
                                                                                    <span style={{ paddingLeft: '10px', paddingRight: '10px' }}>{cartItems.find(cartItem => cartItem.itemId === item._id).quantity}</span>
                                                                                    <button className="btn btn-food" style={{ padding: '10px' }} onClick={() => increaseQuantity(item._id)}>
                                                                                        +
                                                                                    </button>
                                                                                </div>
                                                                            ) : (
                                                                                <button className="btn btn-food" style={{ padding: '10px' }} onClick={() => addToCart(item)}>
                                                                                    Add
                                                                                </button>
                                                                            )}
                                                                        </div>
                                                                        <div className='col-md-4 d-flex' style={{ justifyContent: 'right', padding: "10px" }}>
                                                                            <img src={`data:image/jpeg;base64,${item.image}`} alt={item.itemName} className="food_item_details" />
                                                                        </div>
                                                                        <div className='col-md-12' style={{ textAlign: 'right', marginTop: '10px' }}>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </section>
                        </div>
                    </div>
                </div>

                {/* Show the cart icon with total number of items in the cart */}
                {cartQuantity > 0 && (
                    <div className="shopping-cart fixed-bottom-right " onClick={goToCartPage}>
                        <span className="cart-icon">
                            <img src={require('../assets/img/shopping-cart.gif')} alt="blog" width="45px" className="blog-post-thumbnail" />
                            <span className="cart_shop" style={{ fontWeight: '700', fontSize: '24px', color: '#000' }}>{cartQuantity}</span>
                        </span>
                    </div>
                )}
            </main>
            <Footer />

        </>
    );
};

export default MenuItemsPage;