import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import Navbar from '../Navbar/Navbar';
import Footer from "../Navbar/Footer";


const Services = () => {
    const navigate = useNavigate();
    const handleGetStarted = () => {
        const isLoggedIn = sessionStorage.getItem('user');
        if (!isLoggedIn) {
            Swal.fire({
                title: "Please Log In",
                text: "You need to log in to continue.",
                icon: "error",
            }).then(() => {
                // Redirect to the signin page
                navigate('/signin');
            });
        } else {
            // Redirect to the respective page
            navigate('/services-home'); // Change the path accordingly
        }
    };



    return (
        <>
            <header class="foi-header  bg_header">
                <div class="container">
                    <div className="container bg_navbar">
                        <Navbar />

                    </div>
                </div>
            </header>
            <section className="py-5 mb-5">
                <div className="container" style={{ paddingTop: '70px' }}>
                    <h2>Our Services</h2>
                    <p className="text-muted mb-5">Experience unparalleled expertise and personalized solutions as we deliver top-notch services designed to meet your unique needs, ensuring a seamless and impactful journey towards success.</p>
                    <div className="row">
                        <div className="col-lg-4 mb-4">
                            <div className="card pricing-card active">
                                <div className="card-body">
                                    <h3 className="mb-1">Travel</h3>
                                    <h3 className="mb-1 text-warning">
                                        <img src={require('../assets/images/travel.gif')} alt="user" width="100px" className="avatar" />
                                    </h3>
                                    <p className="mb-4">Embark on unforgettable journeys with our premium travel service, offering seamless experiences tailored to your wanderlust.</p>
                                    <button className="btn btn-primary btn-rounded" onClick={handleGetStarted}>Get Started</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 mb-4">
                            <div className="card pricing-card active">
                                <div className="card-body">
                                    <h3 className="mb-1">Health Care</h3>
                                    <h3 className="text-primary">
                                        <img src={require('../assets/images/health.gif')} alt="user" width="100px" className="avatar" />
                                    </h3>
                                    <p className="mb-4">Empowering wellness through compassionate and comprehensive healthcare services tailored to meet individual needs.</p>
                                    <button className="btn btn-primary btn-rounded" onClick={handleGetStarted}>Get Started</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 mb-4">
                            <div className="card pricing-card active">
                                <div className="card-body">
                                    <h3 className="mb-1">Shopping</h3>
                                    <h3 className="text-primary">
                                        <img src={require('../assets/images/shopping.gif')} alt="user" width="100px" className="avatar" />
                                    </h3>
                                    <p className="mb-4">Effortless shopping made delightful - our service brings convenience to your fingertips for a seamless and enjoyable retail experience.</p>
                                    <button className="btn btn-primary btn-rounded" onClick={handleGetStarted}>Get Started</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-4 mb-4">
                            <div className="card pricing-card active">
                                <div className="card-body">
                                    <h3 className="mb-1">Food</h3>
                                    <h3 className="text-primary">
                                        <img src={require('../assets/images/food.gif')} alt="user" width="100px" className="avatar" />
                                    </h3>
                                    <p className="mb-4">Savor the excellence of our food service, where delectable flavors meet impeccable service for an unparalleled dining experience</p>
                                    <button className="btn btn-primary btn-rounded" onClick={handleGetStarted}>Get Started</button>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 mb-4">
                            <div className="card pricing-card active">
                                <div className="card-body">
                                    <h3 className="mb-1">Entertainment</h3>
                                    <h3 className="text-primary">
                                        <img src={require('../assets/images/entertainment.gif')} alt="user" width="100px" className="avatar" />
                                    </h3>
                                    <p className="mb-4">Immerse yourself in endless entertainment with our captivating and diverse range of services, tailored to delight and inspire</p>
                                    <button className="btn btn-primary btn-rounded" onClick={handleGetStarted}>Get Started</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 mb-4">
                            <div className="card pricing-card active">
                                <div className="card-body">
                                    <h3 className="mb-1">Education</h3>
                                    <h3 className="mb-1 text-warning">
                                        <img src={require('../assets/images/education.gif')} alt="user" width="100px" className="avatar" />
                                    </h3>
                                    <p className="mb-4">Foster academic excellence through dynamic learning environments. Empower minds with our educational services.</p>
                                    <button className="btn btn-primary btn-rounded" onClick={handleGetStarted}>Get Started</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <Footer />
        </>
    );
}

export default Services;
