import { useEffect, useState, useRef } from "react";
import React from 'react';
import { Link, useNavigate } from "react-router-dom";

const Footer = () => {
     // Get the current year dynamically
     const currentYear = new Date().getFullYear();
    return (

        <footer class="foi-footer text-white">
            <div class="container">
                <div class="row footer-content">
                    <div class="col-xl-6 col-lg-7 col-md-8">
                        <h2 class="mb-0">Do you want to know more or just have a question? write to us.</h2>
                    </div>
                    <div class="col-md-4 col-lg-5 col-xl-6 py-3 py-md-0 d-md-flex align-items-center justify-content-end">
                        <Link to='/contact' class="btn btn-danger btn-lg">Contact form</Link>
                    </div>
                </div>
                <div class="row footer-widget-area">
                    <div class="col-md-3">
                        <div class="py-3">
                            <img src="../assets/images/logo-2.png" alt="FOI" />
                        </div>
                        <p class="font-os font-weight-semibold mb3">Get our mobile app</p>
                        <div>
                            <button class="btn btn-app-download mr-2"><img src="../assets/images/ios.svg" alt="App store" /></button>
                            <button class="btn btn-app-download"><img src="../assets/images/android.svg" alt="play store" /></button>
                        </div>
                    </div>
                    <div class="col-md-3 mt-3 mt-md-0">
                        <nav>
                            <ul class="nav flex-column">
                                <li class="nav-item">
                                    <a href="#!" class="nav-link">Account</a>
                                </li>
                                <li class="nav-item">
                                    <a href="#!" class="nav-link">My tasks</a>
                                </li>
                                <li class="nav-item">
                                    <a href="#!" class="nav-link">Projects</a>
                                </li>
                                <li class="nav-item">
                                    <a href="#!" class="nav-link">Edit profile</a>
                                </li>
                                <li class="nav-item">
                                    <a href="#!" class="nav-link">Activity</a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div class="col-md-3 mt-3 mt-md-0">
                        <nav>
                            <ul class="nav flex-column">
                                <li class="nav-item">
                                    <a href="#!" class="nav-link">About</a>
                                </li>
                                <li class="nav-item">
                                    <a href="#!" class="nav-link">Services</a>
                                </li>
                                <li class="nav-item">
                                    <a href="#!" class="nav-link">Careers <span class="badge badge-pill badge-secondary ml-3">Hiring</span></a>
                                </li>
                                <li class="nav-item">
                                    <a href="#!" class="nav-link">Blog</a>
                                </li>
                                <li class="nav-item">
                                    <a href="#!" class="nav-link">Shop with us</a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div class="col-md-3 mt-3 mt-md-0">
                        <p>
                            &copy;{currentYear} <a href="https://www.aihawkssoft.com/" target="_blank" rel="noopener noreferrer" className="text-reset">AI Hawks Software Solutions</a>.
                        </p>
                        <p>All rights reserved.</p>
                        <nav class="social-menu">
                            <a href="#!"><img src="../assets/images/facebook.svg" alt="facebook" /></a>
                            <a href="#!"><img src="../assets/images/instagram.svg" alt="instagram" /></a>
                            <a href="#!"><img src="../assets/images/twitter.svg" alt="twitter" /></a>
                            <a href="#!"><img src="../assets/images/youtube.svg" alt="youtube" /></a>
                        </nav>
                    </div>
                </div>
            </div>
        </footer>

    );
}

export default Footer;
