import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../Navbar/Navbar';
import Footer from "../Navbar/Footer";
import Swal from 'sweetalert2';
import { API_URL } from '../../constants';
import axios from 'axios'; // Import axios for making HTTP requests

const PaymentPage = ({ resetCartCount }) => {
    const navigate = useNavigate();
    const cartItems = JSON.parse(sessionStorage.getItem('cartItems'));
    const totalPrice = sessionStorage.getItem('totalPrice');
    const customerDetails = JSON.parse(sessionStorage.getItem('customerDetails'));
    const selectedAddress = JSON.parse(sessionStorage.getItem('selectedAddress'));
    const user = JSON.parse(sessionStorage.getItem('user'));

    const [modeOfPayment, setModeOfPayment] = useState(''); // State for selected mode of payment

    console.log('customer details', customerDetails);
    console.log('cart Items', cartItems);
    console.log('selected Address', selectedAddress);
    console.log('user', user);

    // Function to handle mode of payment change
    const handleModeOfPaymentChange = (e) => {
        setModeOfPayment(e.target.value);
    };

    // Calculate MRP Totals and Meds Discount
    let mrpTotal = 0;
    let medsDiscount = 0;

    cartItems.forEach(item => {
        mrpTotal += item.actualPrice * item.quantity;
        medsDiscount += (item.actualPrice - item.discountPrice) * item.quantity;
    });

    useEffect(() => {
        const isAuthenticated = sessionStorage.getItem('user'); 
        if (!isAuthenticated) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please login to continue!',
                confirmButtonText: 'OK',
                onClose: () => {
                    navigate('/signin');
                }
            });
        }
    }, [navigate]);

    const handleProceed = () => {
        const paymentData = {
            userId: user._id,
            cartItems: cartItems,
            customerDetails: customerDetails,
            selectedAddress: selectedAddress,
            modeOfPayment: modeOfPayment,
            paymentStatus: 'pending' // Initial status before payment
        };

        // Simulate payment process here (replace this with actual payment gateway integration)
        const simulatePaymentSuccess = true; // Change this based on actual payment result

        if (simulatePaymentSuccess) {
            paymentData.paymentStatus = 'success';

            axios.post(`${API_URL}/user/payment-details`, paymentData)
                .then(response => {
                    console.log('Payment successful:', response.data);
                    resetCartCount();
                    Swal.fire({
                        icon: 'success',
                        title: 'Payment Successful',
                        text: 'Thank you for your payment!',
                        confirmButtonText: 'OK',
                    }).then((result) => {
                        if (result.isConfirmed || result.dismiss === Swal.DismissReason.backdrop || result.dismiss === Swal.DismissReason.esc) {
                            navigate('/services-home');
                        }
                    });
                })
                .catch(error => {
                    console.error('Payment error:', error);
                    Swal.fire({
                        icon: 'error',
                        title: 'Payment Error',
                        text: 'An error occurred while processing your payment. Please try again later.',
                        confirmButtonText: 'OK',
                    });
                });
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Payment Error',
                text: 'Payment failed. Please try again.',
                confirmButtonText: 'OK',
            });
        }
    };

    return (
        <>
            <header className="foi-header bg_header">
                <div className="container">
                    <div className="container bg_navbar">
                        <Navbar />
                    </div>
                </div>
            </header>
            <main>
                <div style={{ paddingTop: "120px" }}>
                    <div className="container" style={{ border: '2px solid #e0e0e0', padding: '20px', borderRadius: '20px' }}>
                        <div className='row justify-content-center'>
                            <div className="col-lg-4 card career-card ml-lg-3 mt-3 mt-lg-0" style={{ padding: '10px', height: '320px', overflowY: 'auto', borderRadius: '20px' }}>
                                <div className='card-body d-flex flex-column' style={{ padding: '20px' }}>
                                    <div style={{ borderBottom: '2px solid #e0e0e0', marginBottom: "5px" }}>
                                        <h5 style={{ color: '#050a4e', paddingBottom: '20px' }}>Payment Details</h5>
                                    </div>
                                    <div className="d-flex justify-content-between" style={{ paddingTop: '20px' }}>
                                        <p style={{ fontWeight: '700', color: '#050a4e', fontSize: "14px" }}>MRP Totals</p>
                                        <p style={{ fontWeight: '700' }}> ₹ {parseFloat(mrpTotal).toFixed(2)}</p>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <p style={{ fontWeight: '700', color: '#050a4e', fontSize: "14px" }}>Meds Discount</p>
                                        <p style={{ fontWeight: '700', color: 'green' }}>- ₹ {parseFloat(medsDiscount).toFixed(2)}</p>
                                    </div>
                                    <div className="d-flex justify-content-between" style={{ borderTop: '2px solid #e0e0e0', marginTop: "5px" }}>
                                        <p style={{ fontWeight: '700', color: '#050a4e', fontSize: "14px" }}>Amount to Pay</p>
                                        <p style={{ fontWeight: '700' }}> ₹ {parseFloat(totalPrice).toFixed(2)}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 card career-card ml-lg-3 mt-3 mt-lg-0" style={{ padding: '10px', height: '320px', overflowY: 'auto', borderRadius: '20px' }}>
                                <div className='card-body d-flex flex-column' style={{ padding: '20px' }}>
                                    <h5>Mode of Payment:</h5>
                                    <select className='form-control' value={modeOfPayment} onChange={handleModeOfPaymentChange}>
                                        <option value="">Select Mode of Payment</option>
                                        <option value="Credit Card">Credit Card</option>
                                        <option value="Debit Card">Debit Card</option>
                                        <option value="UPI">UPI</option>
                                    </select>
                                    {/* Conditionally render the Proceed button */}
                                    {modeOfPayment && (
                                        <button onClick={handleProceed} className="btn btn-primary mt-3">Proceed</button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </>
    );
};

export default PaymentPage;
