import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import Navbar from '../../Navbar/Navbar';
import Footer from '../../Navbar/Footer';
import { API_URL } from '../../../constants';

const LabtestHome = () => {
    const navigate = useNavigate();
    const [diagnosticCenters, setDiagnosticCenters] = useState([]);

    useEffect(() => {
        // Check if the user is authenticated (You can implement your own logic here)
        const isAuthenticated = sessionStorage.getItem('user'); // Example: Check if user data exists in session storage
        if (!isAuthenticated) {
            // If user is not authenticated, redirect to the No Access page
            navigate('/404');
        }
    }, [navigate]);


    useEffect(() => {
        // Check if the user is authenticated (You can implement your own logic here)
        const isAuthenticated = sessionStorage.getItem('user'); // Example: Check if user data exists in session storage
        if (!isAuthenticated) {
            // If user is not authenticated, show a sweet alert and redirect to login
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please login to continue!',
                confirmButtonText: 'OK',
                onClose: () => {
                    navigate('/signin');
                }
            });
        }
    }, [navigate]);

    useEffect(() => {
        const fetchDiagnosticCenters = async () => {
            try {
                const response = await fetch(`${API_URL}/diagnostic-centers`);
                const data = await response.json();
                setDiagnosticCenters(data);
            } catch (error) {
                console.error("Error fetching diagnostic centers:", error);
            }
        };

        fetchDiagnosticCenters();
    }, []);

    const handleCategoryClick = (centerId, centerName) => {
        navigate(`/lab-tests/${centerId}/${encodeURIComponent(centerName)}`);
    };

    return (
        <>
            <header className="foi-header bg_header">
                <div className="container">
                    <div className="container bg_navbar">
                        <Navbar />
                    </div>
                </div>
            </header>
            <main className="page-about">
                <section style={{ paddingTop: "120px" }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div id="landingClientCarousel" className="carousel slide landing-client-carousel" data-ride="carousel">
                                    <div className="carousel-inner" role="listbox">
                                        <div className="carousel-item active">
                                            <div className="d-flex flex-wrap justify-content-center">
                                                <img style={{ borderRadius: '20px' }} src={require('../../assets/img/slide-1.png')} alt="Slide 1" width="1000" className="img-fluid" />
                                            </div>
                                        </div>
                                        <div className="carousel-item">
                                            <div className="d-flex flex-wrap justify-content-center">
                                                <img style={{ borderRadius: '20px' }} src={require('../../assets/img/slide-2.png')} alt="Slide 2" width="1000" className="img-fluid" />
                                            </div>
                                        </div>
                                        <div className="carousel-item">
                                            <div className="d-flex flex-wrap justify-content-center">
                                                <img style={{ borderRadius: '20px' }} src={require('../../assets/img/slide-3.png')} alt="Slide 3" width="1000" className="img-fluid" />
                                            </div>
                                        </div>
                                        <div className="carousel-item">
                                            <div className="d-flex flex-wrap justify-content-center">
                                                <img style={{ borderRadius: '20px' }} src={require('../../assets/img/slide-4.png')} alt="Slide 4" width="1000" className="img-fluid" />
                                            </div>
                                        </div>
                                    </div>
                                    <ol className="carousel-indicators">
                                        <li data-target="#landingClientCarousel" data-slide-to="0" className="active"></li>
                                        <li data-target="#landingClientCarousel" data-slide-to="1"></li>
                                        <li data-target="#landingClientCarousel" data-slide-to="2"></li>
                                        <li data-target="#landingClientCarousel" data-slide-to="3"></li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section style={{ paddingTop: "20px" }}>
                    <div className="container">
                        <h3 style={{ paddingBottom: '20px' }}>Tests By Diagnostic Center</h3>
                        <div className="row">
                            {diagnosticCenters.map((center, index) => (
                                <div key={index} className="col-lg-4 mb-4 d-flex align-items-stretch">
                                    <div className="card pricing-card active w-100" style={{ backgroundColor: '#fbf5fd', borderRadius: "10px", boxShadow: '0 0 15px rgba(24, 24, 24, .161)' }}>
                                        <div className="card-body d-flex flex-column">
                                            <h4 style={{ textTransform: 'capitalize' }}>{center.diagnosticCenterName}</h4>
                                            <div className="mt-auto">
                                                <button
                                                    className="btn btn-primary btn-rounded"
                                                    onClick={() => handleCategoryClick(center.CenterID, center.diagnosticCenterName)}
                                                >
                                                    Browse Tests
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    );
}

export default LabtestHome;
