import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import Navbar from '../Navbar/Navbar';
import Footer from "../Navbar/Footer";

export const HealthHome = () => {
    const navigate = useNavigate();

    useEffect(() => {
        // Check if the user is authenticated (You can implement your own logic here)
        const isAuthenticated = sessionStorage.getItem('user'); // Example: Check if user data exists in session storage
        if (!isAuthenticated) {
            // If user is not authenticated, redirect to the No Access page
            navigate('/404');
        }
    }, [navigate]);


    useEffect(() => {
        // Check if the user is authenticated (You can implement your own logic here)
        const isAuthenticated = sessionStorage.getItem('user'); // Example: Check if user data exists in session storage
        if (!isAuthenticated) {
            // If user is not authenticated, show a sweet alert and redirect to login
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please login to continue!',
                confirmButtonText: 'OK',
                onClose: () => {
                    navigate('/signin');
                }
            });
        }
    }, [navigate]);
    return (
        <>
            <header class="foi-header  bg_header">
                <div class="container">
                    <div className="container bg_navbar">
                        <Navbar />

                    </div>
                </div>
            </header>
            <main class="page-about">
                <section style={{ paddingTop: "120px" }}>
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <div id="landingClientCarousel" class="carousel slide landing-client-carousel" data-ride="carousel">
                                    <div class="carousel-inner" role="listbox">
                                        <div class="carousel-item active">
                                            <div class="d-flex flex-wrap justify-content-center">
                                                <img style={{ borderRadius: '20px' }} src={require('../assets/img/slide-1.png')} alt="Slack" width="1000" class="img-fluid" />
                                            </div>
                                        </div>
                                        <div class="carousel-item">
                                            <div class="d-flex flex-wrap justify-content-center">
                                                <img style={{ borderRadius: '20px' }} src={require('../assets/img/slide-2.png')} alt="Slack" width="1000" class="img-fluid" />
                                            </div>
                                        </div>
                                        <div class="carousel-item">
                                            <div class="d-flex flex-wrap justify-content-center">
                                                <img style={{ borderRadius: '20px' }} src={require('../assets/img/slide-3.png')} alt="Slack" width="1000" class="img-fluid" />
                                            </div>
                                        </div>
                                        <div class="carousel-item">
                                            <div class="d-flex flex-wrap justify-content-center">
                                                <img style={{ borderRadius: '20px' }} src={require('../assets/img/slide-4.png')} alt="Slack" width="1000" class="img-fluid" />
                                            </div>
                                        </div>
                                    </div>
                                    <ol class="carousel-indicators">
                                        <li data-target="#landingClientCarousel" data-slide-to="0" class="active"></li>
                                        <li data-target="#landingClientCarousel" data-slide-to="1"></li>
                                        <li data-target="#landingClientCarousel" data-slide-to="2"></li>
                                        <li data-target="#landingClientCarousel" data-slide-to="3"></li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section >
                    <div className="container">
                        <p className="text-muted mb-5">Experience unparalleled expertise and personalized solutions as we deliver top-notch services designed to meet your unique needs, ensuring a seamless and impactful journey towards success.</p>
                        <div className="row">
                            <div className="col-lg-4 mb-4">
                                <div className="card pricing-card active">
                                    <div className="card-body">
                                        <h6 className="mb-1">Book Dr. Appointment</h6>
                                        <h3 className="mb-1 text-warning">
                                            <img src={require('../assets/img/healthcare.gif')} alt="user" width="80px" className="avatar" />
                                        </h3>
                                        <Link className="btn btn-primary btn-rounded" to={'/category'}>Book Now</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 mb-4">
                                <div className="card pricing-card active">
                                    <div className="card-body">
                                        <h6 className="mb-1">Buy Medicines</h6>
                                        <h3 className="text-primary">
                                            <img src={require('../assets/img/medicine.gif')} alt="user" width="80px" className="avatar" />
                                        </h3>
                                        <Link className="btn btn-primary btn-rounded" to={'/medicine-home'}>Buy Now</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 mb-4">
                                <div className="card pricing-card active">
                                    <div className="card-body">
                                        <h6 className="mb-1">Lab Tests</h6>
                                        <h3 className="text-primary">
                                            <img src={require('../assets/img/labtest.gif')} alt="user" width="80px" className="avatar" />
                                        </h3>
                                        <Link to='/lab-test-home' className="btn btn-primary btn-rounded">Get Started</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>


            <Footer />
        </>
    )
}

export default HealthHome;
