import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import Navbar from '../Navbar/Navbar';
import Footer from "../Navbar/Footer";


const Error404 = () => {

    return (
        <>
            <header class="foi-header  bg_header">
                <div class="container">
                    <div className="container bg_navbar">
                        <Navbar />

                    </div>
                </div>
            </header>
            <main class="page-error" style={{paddingBottom:'10px', paddingTop:'100px'}}>
                <div class="container">
                    <section class="error-section">
                        <h1 class="error-code">404</h1>
                        <p class="error-message">Sorry, the page you are looking for could not be found.</p>
                        <img src="assets/images/404.png" alt="page not found" class="error-img" />
                        <Link to='/' class="btn btn-info btn-error-action">Return Home</Link>
                    </section>
                </div>
            </main>
            <Footer />
        </>
    );
}

export default Error404;
