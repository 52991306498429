import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Navbar from '../Navbar/Navbar';
import Footer from "../Navbar/Footer";
import { API_URL } from '../../constants';

const Orders = () => {
    const [groupedOrders, setGroupedOrders] = useState({});
    const [loading, setLoading] = useState(true);
    const [selectedDate, setSelectedDate] = useState(null); // State to store selected date
    const [lastOrderDate, setLastOrderDate] = useState(null); // State to store last order date

    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const user = JSON.parse(sessionStorage.getItem('user'));
                if (user) {
                    const userId = user._id;
                    const response = await axios.get(`${API_URL}/orders/${userId}`);
                    if (response.data.success) {
                        setGroupedOrders(response.data.groupedOrders);
                        updateLastOrderDate(response.data.groupedOrders);
                    } else {
                        console.error('Failed to fetch orders:', response.data.error);
                    }
                }
            } catch (error) {
                console.error('Error fetching orders:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchOrders();
    }, []);

    useEffect(() => {
        // Set selectedDate to lastOrderDate when lastOrderDate is available
        if (lastOrderDate && selectedDate === null) {
            setSelectedDate(lastOrderDate);
        }
    }, [lastOrderDate, selectedDate]);

    const updateLastOrderDate = (orders) => {
        let latestOrderDate = null;
        Object.values(orders).forEach(ordersByPharmacy => {
            if (ordersByPharmacy.length > 0) {
                const orderDate = new Date(ordersByPharmacy[0].createdOn);
                if (!latestOrderDate || orderDate > latestOrderDate) {
                    latestOrderDate = orderDate;
                }
            }
        });
        setLastOrderDate(latestOrderDate ? latestOrderDate.toISOString().split('T')[0] : null);
    };

    const handleDateChange = (event) => {
        const selectedDate = event.target.value; // Assuming you get the date in ISO format from the date picker
        setSelectedDate(selectedDate);
    };

    const filteredOrders = selectedDate
        ? Object.entries(groupedOrders).reduce((filtered, [pharmacyName, orders]) => {
            const filteredOrders = orders.filter(order => {
                const orderDate = new Date(order.createdOn).toISOString().split('T')[0];
                return orderDate === selectedDate;
            });
            if (filteredOrders.length > 0) {
                filtered[pharmacyName] = filteredOrders;
            }
            return filtered;
        }, {})
        : groupedOrders;

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <header className="foi-header bg_header">
                <div className="container">
                    <div className="container bg_navbar">
                        <Navbar />
                    </div>
                </div>
            </header>
            <section className="py-5 mb-5" style={{ marginTop: '100px' }}>
                <div className="container" style={{ paddingTop: '30px', paddingBottom: '10px', backgroundColor: '#f2f2f2', borderRadius: '20px' }}>
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <h3 style={{ padding: '10px' }}>Orders</h3>
                        <div className="col-md-4 mb-3 input-group" style={{ maxWidth: '350px' }}>
                            <div className="input-group-prepend">
                                <span style={{ fontSize: "14px", fontWeight: '700' }} className="input-group-text">Filter By Date</span>
                            </div>
                            <input type="date" id="orderDate" className="form-control" value={selectedDate} onChange={handleDateChange} />
                        </div>
                    </div>

                    {Object.keys(filteredOrders).length === 0 ? (
                        <p>No orders found.</p>
                    ) : (
                        Object.entries(filteredOrders).map(([pharmacyName, orders]) => (
                            <>
                                <div className="career-card mb-3" key={pharmacyName} style={{ borderRadius: '20px', border: 'none', backgroundColor: "white" }}>
                                    <div className="card-body" style={{ padding: '20px', borderRadius: '30px' }}>
                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                            <h5 className="card-title mb-0">{pharmacyName}</h5>
                                            <p style={{ fontWeight: "700" }}>Order Date: {orders[0] ? new Date(orders[0].createdOn).toLocaleDateString() : 'N/A'}</p>
                                        </div>
                                        <ul className="list-group list-group-flush careers-list-group">
                                            {orders.map(order => (
                                                <li className="list-group-item d-flex justify-content-between align-items-center" key={order.orderId} style={{ padding: "10px", borderRadius: '0px' }}>
                                                    <div className="d-flex align-items-center">
                                                        <h6 className="position-title mb-0">{order.cartItem.name}</h6>
                                                    </div>
                                                    <div className="ml-auto">
                                                        <p className="mb-0 position-location text-right">Quantity: {order.cartItem.quantity}</p>
                                                        <p className="mb-0 position-location text-right">Price: ₹ {order.cartItem.discountPrice.toFixed(2)}</p>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </>
                        ))
                    )}
                </div>
            </section>
        </>
    );
};

export default Orders;
