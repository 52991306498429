import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../Navbar/Navbar';
import Footer from "../Navbar/Footer";
import Swal from 'sweetalert2';
import { API_URL } from '../../constants';
import './CategoryMedicines.css'

const CartPage = ({ cart, totalItemsInCart, increaseQuantity, decreaseQuantity }) => {
    const navigate = useNavigate();
    const [medicines, setMedicines] = useState([]);
    const [mrpTotal, setMrpTotal] = useState(0);
    const [discountTotal, setDiscountTotal] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    
    useEffect(() => {
        // Check if the user is authenticated (You can implement your own logic here)
        const isAuthenticated = sessionStorage.getItem('user'); // Example: Check if user data exists in session storage
        if (!isAuthenticated) {
            // If user is not authenticated, redirect to the No Access page
            navigate('/404');
        }
    }, [navigate]);


    useEffect(() => {
        // Check if the user is authenticated (You can implement your own logic here)
        const isAuthenticated = sessionStorage.getItem('user'); // Example: Check if user data exists in session storage
        if (!isAuthenticated) {
            // If user is not authenticated, show a sweet alert and redirect to login
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please login to continue!',
                confirmButtonText: 'OK',
                onClose: () => {
                    navigate('/signin');
                }
            });
        }
    }, [navigate]);

    useEffect(() => {
        const fetchMedicines = async () => {
            try {
                const medicineIds = Object.keys(cart); // Get IDs of medicines in the cart
                const promises = medicineIds.map(async (id) => {
                    const response = await fetch(`${API_URL}/pharmacyMedicine/product/${id}`);
                    if (response.ok) {
                        const data = await response.json();
                        return data.medicine;
                    } else {
                        throw new Error('Failed to fetch medicine details');
                    }
                });
                const medicineDetails = await Promise.all(promises);
                setMedicines(medicineDetails);
                console.log(medicineDetails);
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching medicine details:', error);
                Swal.fire("Error", "An error occurred while fetching medicine details.", "error");
            }
        };

        fetchMedicines();
    }, [cart]);

    useEffect(() => {
        let mrpTotal = 0;
        let discountTotal = 0;
        medicines.forEach((medicine) => {
            if (cart[medicine._id]) {
                const quantity = cart[medicine._id];
                mrpTotal += quantity * medicine.actualPrice;
                discountTotal += quantity * (medicine.actualPrice - medicine.discountPrice);
            }
        });
        const totalPrice = mrpTotal - discountTotal;
        setMrpTotal(mrpTotal);
        setDiscountTotal(discountTotal);
        setTotalPrice(totalPrice);
    }, [cart, medicines]);

    const handleCheckout = () => {
        // Prepare cart items data to store in session
        const cartItemsData = Object.keys(cart).map(itemId => ({
            name: medicines.find(medicine => medicine._id === itemId).medicineName,
            quantity: cart[itemId],
            discountPrice: medicines.find(medicine => medicine._id === itemId).discountPrice,
            actualPrice: medicines.find(medicine => medicine._id === itemId).actualPrice,
            pharmacyName: medicines.find(medicine => medicine._id === itemId).pharmacyName,
            pharmacyId: medicines.find(medicine => medicine._id === itemId).userId,
            productId: medicines.find(medicine => medicine._id === itemId)._id,
            amountToPay: medicines.find(medicine => medicine._id === itemId).discountPrice * cart[itemId],
        }));

        // Store cart items data in session
        sessionStorage.setItem('cartItems', JSON.stringify(cartItemsData));
        sessionStorage.setItem('totalPrice', totalPrice);

        // Clear cart items from sessionStorage
        sessionStorage.removeItem('cart');

        // Forward to next page
        navigate('/customer-details', { state: { totalPrice } });
    };


    return (
        <>
            <header className="foi-header bg_header">
                <div className="container">
                    <div className="container bg_navbar">
                        <Navbar />
                    </div>
                </div>
            </header>
            <main>
                <div style={{ paddingTop: "120px" }}>
                    <div className="container" style={{ border: '2px solid #e0e0e0', padding: '20px', borderRadius: '20px' }}>
                        <h5>
                            {medicines.length > 0 && (
                                <>
                                    <span style={{ fontSize: '18px', color: '#000', marginLeft: '5px' }}>Cart items</span>
                                    <span style={{ fontSize: '14px', color: '#888', marginLeft: '5px' }}>
                                        ({Object.keys(cart).length} {Object.keys(cart).length === 1 ? 'item' : 'items'})
                                    </span>
                                </>
                            )}
                        </h5>
                        {isLoading ? (
                            <div className="col-lg-12 medicines-list empty-cart-message">
                                <p>Loading.....</p>
                            </div>
                        ) : (
                            <>
                                <div className="row">
                                    {medicines.length > 0 ? (
                                        <>
                                            <div className="col-lg-8 medicines-list" style={{ height: '400px', overflowY: 'auto', paddingTop: "20px", paddingBottom: '20px' }}>
                                                {medicines.map((medicine, index) => (
                                                    <div key={index} className="card career-card mb-2" style={{ borderRadius: '20px', padding: '10px', }}>
                                                        <div className="card-body d-flex flex-md-row justify-content-between align-items-start align-items-md-center" style={{ padding: '10px' }}>
                                                            <div className="col-md-6 mb-3 mb-md-0">
                                                                <h5 className="position-title" style={{ fontSize: "16px", fontWeight: '500' }}>{medicine.medicineName}</h5>
                                                                <span className="position-title" style={{ fontSize: "14px", fontWeight: '                                                                400', color: 'gray' }}>{medicine.manufacturerName}</span>
                                                                </div>
                                                                <div className="d-flex flex-column flex-md-row align-items-start align-items-md-center">
                                                                    <h5 className="mb-2 mb-md-0" style={{ fontSize: '16px', color: '#000' }}>
                                                                        <span style={{ textDecoration: "line-through", color: '#808080', fontWeight: '400' }}>MRP ₹ {medicine.actualPrice.toFixed(2)}</span>
                                                                        <br />
                                                                        <span style={{ fontSize: '16px', color: '#262c67' }}>MRP ₹ {medicine.discountPrice.toFixed(2)}</span>
                                                                    </h5>
                                                                    <div className="d-flex align-items-center ml-0 ml-md-3">
                                                                        <button className="btn btn-primary btn-sm" onClick={() => decreaseQuantity(medicine._id)}>-</button>
                                                                        <span className="mx-2 btn btn-secondary btn-sm" style={{ fontSize: '18px', fontWeight: '700' }}>{cart[medicine._id]}</span>
                                                                        <button className="btn btn-primary btn-sm" onClick={() => increaseQuantity(medicine._id)}>+</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                                <div className="col-lg-3 card career-card ml-lg-3 mt-3 mt-lg-0" style={{ padding: '10px', height: '320px', overflowY: 'auto', borderRadius: '20px' }}>
                                                    <div className='card-body d-flex flex-column' style={{ padding: '20px' }}>
                                                        <div style={{ borderBottom: '2px solid #e0e0e0', marginBottom: "5px" }}>
                                                            <h5 style={{ color: '#050a4e', paddingBottom: '20px' }}>Payment Details</h5>
                                                        </div>
                                                        <div className="d-flex justify-content-between" style={{ paddingTop: '20px' }}>
                                                            <p style={{ fontWeight: '700', color: '#050a4e', fontSize: "14px" }}>MRP Totals</p>
                                                            <p style={{ fontWeight: '700' }}> ₹ {mrpTotal.toFixed(2)}</p>
                                                        </div>
                                                        <div className="d-flex justify-content-between">
                                                            <p style={{ fontWeight: '700', color: '#050a4e', fontSize: "14px" }}>Meds Discount</p>
                                                            <p style={{ fontWeight: '700', color: 'green' }}>- ₹ {discountTotal.toFixed(2)}</p>
                                                        </div>
                                                        <div className="d-flex justify-content-between" style={{ borderTop: '2px solid #e0e0e0', marginTop: "5px" }}>
                                                            <p style={{ fontWeight: '700', color: '#050a4e', fontSize: "14px" }}>Amount to Pay</p>
                                                            <p style={{ fontWeight: '700' }}> ₹ {totalPrice.toFixed(2)}</p>
                                                        </div>
                                                        {totalItemsInCart > 0 && (
                                                            <button className="btn btn-primary mt-3" onClick={handleCheckout}>Checkout</button>
                                                        )}
                                                    </div>
                                                </div>
                                            </>
    
                                        ) : (
                                            <div className="col-lg-12 medicines-list empty-cart-message">
                                                <p>Your cart is empty.</p>
                                            </div>
                                        )}
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </main>
                <Footer />
            </>
        );
    };
    
    export default CartPage;
    
