import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import Navbar from '../Navbar/Navbar';
import Footer from "../Navbar/Footer";


const CustHome = () => {
    const navigate = useNavigate();

    useEffect(() => {
        // Check if the user is authenticated (You can implement your own logic here)
        const isAuthenticated = sessionStorage.getItem('user'); // Example: Check if user data exists in session storage
        if (!isAuthenticated) {
            // If user is not authenticated, redirect to the No Access page
            navigate('/404');
        }
    }, [navigate]);


    useEffect(() => {
        // Check if the user is authenticated (You can implement your own logic here)
        const isAuthenticated = sessionStorage.getItem('user'); // Example: Check if user data exists in session storage
        if (!isAuthenticated) {
            // If user is not authenticated, show a sweet alert and redirect to login
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please login to continue!',
                confirmButtonText: 'OK',
                onClose: () => {
                    navigate('/signin');
                }
            });
        }
    }, [navigate]);

    const handleGetStartedClick = () => {
        // You can add specific logic here for what should happen when the user clicks "Get Started"
        // For now, let's just navigate to the appropriate route
        navigate('/travel');
    };
    return (
        <>
            <header class="foi-header  bg_header">
                <div class="container">
                    <div className="container bg_navbar">
                        <Navbar />

                    </div>
                </div>
            </header>
            <section className="py-5 mb-5">
                <div className="container" style={{ paddingTop: '70px' }}>
                    <h2>Services</h2>
                    <p className="text-muted mb-5">Experience unparalleled expertise and personalized solutions as we deliver top-notch services designed to meet your unique needs, ensuring a seamless and impactful journey towards success.</p>
                    <div className="row">
                        <div className="col-lg-4 mb-4">
                            <div className="card pricing-card active">
                                <div className="card-body">
                                    <h3 className="mb-1">Travel</h3>
                                    <h3 className="mb-1 text-warning">
                                        <img src={require('../assets/images/travel.gif')} alt="user" width="100px" className="avatar" />
                                    </h3>
                                    <p className="mb-4">Embark on unforgettable journeys with our premium travel service, offering seamless experiences tailored to your wanderlust.</p>
                                    <Link className="btn btn-primary btn-rounded" to={'/travel'}>Get Started</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 mb-4">
                            <div className="card pricing-card active">
                                <div className="card-body">
                                    <h3 className="mb-1">Health Care</h3>
                                    <h3 className="text-primary">
                                        <img src={require('../assets/images/health.gif')} alt="user" width="100px" className="avatar" />
                                    </h3>
                                    <p className="mb-4">Empowering wellness through compassionate and comprehensive healthcare services tailored to meet individual needs.</p>
                                    <Link className="btn btn-primary btn-rounded" to={'/health-home'}>Get Started</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 mb-4">
                            <div className="card pricing-card active">
                                <div className="card-body">
                                    <h3 className="mb-1">Shopping</h3>
                                    <h3 className="text-primary">
                                        <img src={require('../assets/images/shopping.gif')} alt="user" width="100px" className="avatar" />
                                    </h3>
                                    <p className="mb-4">Effortless shopping made delightful - our service brings convenience to your fingertips for a seamless and enjoyable retail experience.</p>
                                    <button className="btn btn-primary btn-rounded">Get Started</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-4 mb-4">
                            <div className="card pricing-card active">
                                <div className="card-body">
                                    <h3 className="mb-1">Food</h3>
                                    <h3 className="text-primary">
                                        <img src={require('../assets/images/food.gif')} alt="user" width="100px" className="avatar" />
                                    </h3>
                                    <p className="mb-4">Savor the excellence of our food service, where delectable flavors meet impeccable service for an unparalleled dining experience</p>
                                    <Link className="btn btn-primary btn-rounded" to={'/food-home'}>Get Started</Link>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 mb-4">
                            <div className="card pricing-card active">
                                <div className="card-body">
                                    <h3 className="mb-1">Entertainment</h3>
                                    <h3 className="text-primary">
                                        <img src={require('../assets/images/entertainment.gif')} alt="user" width="100px" className="avatar" />
                                    </h3>
                                    <p className="mb-4">Immerse yourself in endless entertainment with our captivating and diverse range of services, tailored to delight and inspire</p>
                                    <Link to={'/homeEntertain'} className="btn btn-primary btn-rounded">Get Started</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 mb-4">
                            <div className="card pricing-card active">
                                <div className="card-body">
                                    <h3 className="mb-1">Education</h3>
                                    <h3 className="mb-1 text-warning">
                                        <img src={require('../assets/images/education.gif')} alt="user" width="100px" className="avatar" />
                                    </h3>
                                    <p className="mb-4">Foster academic excellence through dynamic learning environments. Empower minds with our educational services.</p>
                                    <button className="btn btn-primary btn-rounded">Get Started</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <Footer />
        </>
    );
}

export default CustHome;
