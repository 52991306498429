import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import Navbar from '../Navbar/Navbar';
import Footer from "../Navbar/Footer";
import { API_URL } from '../../constants';

const ForgotPassword = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [otpSent, setOtpSent] = useState(false);
    const [otpVerified, setOtpVerified] = useState(false);
    const [otp, setOtp] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [emailError, setEmailError] = useState("");
    const [otpError, setOtpError] = useState("");
    const [passwordError, setPasswordError] = useState("");

    useEffect(() => {
        const disableBack = () => {
            window.history.pushState(null, null, window.location.href);
            window.onpopstate = () => {
                window.history.go(1);
            };
        };
        disableBack();

        return () => {
            window.onpopstate = null;
        };
    }, []);

    const handleSendOtp = async (e) => {
        e.preventDefault();
        if (!email) {
            setEmailError("Email is required");
            return;
        }
        try {
            const response = await fetch(`${API_URL}/send-otp-mail`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });
            const data = await response.json();
            if (data.status === 200) {
                Swal.fire({
                    title: 'OTP Sent!',
                    text: 'Please check your email for the OTP.',
                    icon: 'success',
                    confirmButtonText: 'OK'
                });
                setOtpSent(true);
                // Store email in session storage
                sessionStorage.setItem('otp', data.otp);
                sessionStorage.setItem('resetEmail', email);
            } else {
                Swal.fire({
                    title: 'Error',
                    text: data.message,
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            }
        } catch (error) {
            Swal.fire({
                title: 'Error',
                text: 'Something went wrong. Please try again.',
                icon: 'error',
                confirmButtonText: 'OK'
            });
        }
    };

    const handleVerifyOtp = (e) => {
        e.preventDefault();
        const storedOtp = sessionStorage.getItem('otp');
        Swal.fire({
            title: 'Verifying OTP',
            text: 'Please wait...',
            icon: 'loading',
            timer: 5000,
            showConfirmButton: false,
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });

        setTimeout(() => {
            if (!otp || otp !== storedOtp) {
                setOtpError("Invalid OTP");
                return;
            }
            // Clear OTP from session storage
            sessionStorage.removeItem('otp');
            setOtpVerified(true);
            Swal.fire({
                title: 'OTP Verified!',
                text: 'You can now reset your password.',
                icon: 'success',
                confirmButtonText: 'OK'
            });
        }, 5000);
    };

    const handleResetPassword = async (e) => {
        e.preventDefault();
        if (!newPassword || !confirmPassword) {
            setPasswordError("Both fields are required");
            return;
        }
        if (newPassword !== confirmPassword) {
            setPasswordError("Passwords do not match");
            return;
        }

        // Retrieve email from session storage
        const email = sessionStorage.getItem('resetEmail');

        try {
            const response = await fetch(`${API_URL}/reset-password`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, newPassword }), // Send email and new password
            });
            const data = await response.json();
            if (data.status === 200) {
                Swal.fire({
                    title: 'Password Reset!',
                    text: 'Your password has been successfully reset.',
                    icon: 'success',
                    confirmButtonText: 'OK'
                });
                navigate('/signin');
            } else {
                Swal.fire({
                    title: 'Error',
                    text: data.message,
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            }
        } catch (error) {
            Swal.fire({
                title: 'Error',
                text: 'Something went wrong. Please try again.',
                icon: 'error',
                confirmButtonText: 'OK'
            });
        }
    };

    const handleCancel = () => {
        navigate('/signin');
    };

    return (
        <>
            <header className="foi-header bg_header">
                <div className="container">
                    <div className="container bg_navbar">
                        <Navbar />
                    </div>
                </div>
            </header>
            <main className="page-auth" style={{ paddingTop: "50px" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 offset-lg-1">
                            <section className="auth-wrapper">
                                <div className="row">
                                    <div className="col-md-6 mb-4 mb-md-0">
                                        <h2 className="auth-section-title">Forgot Password</h2>
                                        <p className="auth-section-subtitle">Enter your registered mail to continue.</p>
                                        {!otpSent ? (
                                            <form onSubmit={handleSendOtp}>
                                                <div className="form-group">
                                                    <label htmlFor="email">Registered email <sup>*</sup></label>
                                                    <input
                                                        type="email"
                                                        className="form-control"
                                                        id="email"
                                                        name="email"
                                                        placeholder="Email *"
                                                        value={email}
                                                        onChange={(e) => {
                                                            setEmail(e.target.value);
                                                            setEmailError("");
                                                        }}
                                                    />
                                                    {emailError && <p className="text-danger">{emailError}</p>}
                                                </div>
                                                <button className="btn btn-primary btn-auth-submit" type="submit">Send OTP</button>
                                            </form>
                                        ) : !otpVerified ? (
                                            <form onSubmit={handleVerifyOtp}>
                                                <div className="form-group">
                                                    <label htmlFor="otp">Enter OTP <sup>*</sup></label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="otp"
                                                        name="otp"
                                                        placeholder="OTP *"
                                                        value={otp}
                                                        onChange={(e) => {
                                                            setOtp(e.target.value);
                                                            setOtpError("");
                                                        }}
                                                    />
                                                    {otpError && <p className="text-danger">{otpError}</p>}
                                                </div>
                                                <button className="btn btn-primary btn-auth-submit" type="submit">Verify OTP</button>
                                            </form>
                                        ) : (
                                            <form onSubmit={handleResetPassword}>
                                                <div className="form-group">
                                                    <label htmlFor="newPassword">New Password <sup>*</sup></label>
                                                    <input
                                                        type="password"
                                                        className="form-control"
                                                        id="newPassword"
                                                        name="newPassword"
                                                        placeholder="New Password *"
                                                        value={newPassword}
                                                        onChange={(e) => {
                                                            setNewPassword(e.target.value);
                                                            setPasswordError("");
                                                        }}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="confirmPassword">Confirm Password <sup>*</sup></label>
                                                    <input
                                                        type="password"
                                                        className="form-control"
                                                        id="confirmPassword"
                                                        name="confirmPassword"
                                                        placeholder="Confirm Password *"
                                                        value={confirmPassword}
                                                        onChange={(e) => {
                                                            setConfirmPassword(e.target.value);
                                                            setPasswordError("");
                                                        }}
                                                    />
                                                    {passwordError && <p className="text-danger">{passwordError}</p>}
                                                </div>
                                                <div className="button-group" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <button className="btn btn-primary btn-auth-submit" type="submit">Reset Password</button>
                                                    <button className="btn btn-secondary btn-auth-submit" type="button" onClick={handleCancel}>Cancel</button>
                                                </div>
                                            </form>
                                        )}
                                        <p className="mb-0">
                                            <Link to="/signup" className="text-dark font-weight-bold">New User? Sign Up</Link>
                                        </p>
                                    </div>
                                    <div className="col-md-6 d-flex align-items-center">
                                        <img src="assets/images/login.png" alt="login" className="img-fluid" />
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </>
    );
};

export default ForgotPassword;
